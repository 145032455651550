<template>
<div style="position: relative;">
   <v-tabs class="rd" hide-slider color='rgba(255, 0, 0, 0.5)'  exact>
    <v-tab :ripple="false" :to="{ name: 'Home'}" @click="navClick('Home')" >{{ $t('headerNavHome') }}</v-tab>
    <v-tab :ripple="false"  :to="{ name: 'Marketplace'}" @click="navClick('Marketplace')">{{ $t('headerNavMarketplace') }}</v-tab>
    <v-tab :ripple="false" class="ml-1" :to="{name:'Rwa'}" @click="navClick('Rwa')" >{{ $t('rwa_s') }}</v-tab> 
    
    <v-tab :ripple="false" class="ml-1" :to="{name:'LaunchPad'}" @click="navClick('LaunchPad')" >{{ $t('nav_launchpad') }}</v-tab> 
     <v-tab :ripple="false" class="ml-1" :to="{name:'Drops'}" @click="navClick('Drops')" >{{ $t('Drops') }}</v-tab> 
     
    <v-tab   @click="clickMore" >{{ $t('nav_learnmore') }}</v-tab> 
    <v-tab class="icon" :ripple="false" :to="{name:'Promo'}" v-if="chain == 'UPTICK'"  @click="navClick('Promo')">{{ $t('headerNavPromo') }}</v-tab>
  </v-tabs>
  <div class="list" v-if="isShow">
    <div class="item" v-for="(item,index) in list" :key="index">
        <div class="name" @click="clickItem(item)">{{item.name}}</div>
    </div>
    
  </div>
</div>
 
  
  <!-- <div class="d-flex flex-row">
    <div class="table ml-2 mr-2" :class="{border:currentName == 'Home'}" @click="toLink('Home')">{{ $t('headerNavHome') }}</div>
    <div class="table ml-2 mr-2" :class="{border:currentName == 'Marketplace'}" @click="toLink('Marketplace')">{{ $t('headerNavMarketplace') }}</div>
    <div class="table ml-2 mr-2" :class="{border:currentName == 'LaunchPad'}" @click="toLink('LaunchPad')">{{ $t('nav_launchpad') }}</div>
    <div class="table ml-2 mr-2" @click="toLink('Learn')">{{ $t('nav_learnmore') }}</div>
    <div class="table ml-2 mr-2" :class="{border:currentName == 'Promo'}" @click="toLink('Promo')">{{ $t('headerNavPromo') }}</div>
  </div> -->
</template>

<script lang="js">

export default {
  name: 'Nav',
  inheritAttrs: false,
  data: function() {
    return {
      currentName:'Home',
      isShow:false,
      chain:'UPTICK',
      list:[
        {
          name:'Collections'
        },
        {
          name:'Drops'
        },
      ]
    };
  },
  mounted(){
         window.addEventListener("click", this.clickOther);
          // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
     let currentSymbol = `${this.$env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL}` 
    if(currentChain == 'BSC'){
     
       this.chain = 'BNB'
    }else if( currentChain == 'POLYGON'){
      
         this.chain = 'MATIC'
    }else if( currentChain == 'ARBITRUM'){
         this.chain = currentSymbol
    }
   
  },
  methods:{
    navClick(name){
       this.$gtag.event('导航切换-'+name, { 'event_category': 'Click', 'event_label': name })
      console.log("navClick",name);
    },
    clickOther(){
       this.isShow = false
    },
    clickItem(item){
      debugger
      console.log("clickItem",item);
      if(item.name == 'Drops'){
        this.$router.push({name:"Drops"})
      }else{
         this.$router.push({name:"LaunchPad"})
      }
    },
    toLink(name){
         
      if(name == 'Learn'){
          window.open('https://www.uptick.network/marketplace.html', '_blank');
      }else{
         this.currentName = name
           this.$router.push({name:name})
      }
  
   
    },
  
    clickMore(){ 
      this.$gtag.event('导航切换-learnMore', { 'event_category': 'Click', 'event_label': 'learnMore' })
      window.open('https://www.uptick.network/marketplace.html', '_blank');
      window.location.reload()
    },
    clickLaunchpad(){
      console.log("clickLaunchpad");
      this.isShow = !this.isShow
    }
  }
};
</script>

<style lang="scss" scoped>
.list{
position: absolute;
top: 40px;
right: 155px;
  width: 108px;
	height: 103px;
	background-image: linear-gradient(
		#ffffff, 
		#ffffff), 
	linear-gradient(
		#6f58d9, 
		#6f58d9);
	background-blend-mode: normal, 
		normal;
	box-shadow: 0px 0px 9px 0px 
		rgba(0, 0, 0, 0.09);
	border-radius: 5px;
  padding: 20px;
  .item{
	  cursor:pointer;
    font-family: Helvetica;
	font-size: 13px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 35px;
	letter-spacing: 0px;
	color: #270645;
  }
}

.table
{
  cursor: pointer;
  min-width: 70px;
  text-align: center;
  font-family: Helvetica;
	font-size: 14px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
  color: #766983;
  &.border{
     color: #7800f4;
  }
}
// .v-tabs.rd {
//   width: unset;
//   flex: 0 1 auto;
//   &::v-deep .v-item-group {
//     flex: 0 1 auto;
//     width: unset;
//     .v-slide-group__content, .v-slide-group__wrapper {
//       flex: 0 1 auto;
//     }
//   }
 
//   .v-tab {
//     padding-right: 0px;
//     padding-left: 4px;
//   }
//   .img{
//     margin-left: -18px;
//     margin-top:12px;
//   }
  
// }


.v-tabs.rd {
  width: unset;
  flex: 0 1 auto;
  position: relative;
  
  &::v-deep .v-item-group {
    flex: 0 1 auto;
    width: unset;
        background-color: rgba(255, 255, 255, 0) !important;
    .v-slide-group__content, .v-slide-group__wrapper {
      flex: 0 1 auto;
    }
  }
 
  .v-tab {
    margin-right: 0px;
    margin-left: 5px;
    color: #7800f4;
    min-width: 60px;
    padding:0 10px;
  }
  .img{
    margin-left: -18px;
    margin-top:12px;
  }
  .theme--light.v-tabs > .v-tabs-bar {
    background-color: rgba(255, 255, 255, 0);
}
  
}
</style>
