<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
        <span class="title" v-if="lastBid" :class="{darkFont:this.$vuetify.theme.dark }"> {{ $t("detailIndexPlaceBid") }}</span>
        <h3 class="content" v-if="lastBid">
          {{ $t("popupAuctionDefectPriceOnceTheBid") }}
        </h3>
        <h3 class="title" v-if="purchBid" :class="{darkFont:this.$vuetify.theme.dark }">
          {{ $t("popupBuyPriceBuyNow") }}
        </h3>
        <h3 class="contentPrice " v-if="isguarant && showMessage" :class="{darkFont:this.$vuetify.theme.dark }">
          {{ $t("hasReservePrice") }}
        </h3>

        <div class="form">
          <div class="ti-in">
            <span class="titles" :class="{darkFont:this.$vuetify.theme.dark }" v-if="lastBid">{{text}}<span class="price"> {{price}}</span>{{unit}}</span>
            <!-- <span class="titles" v-if="purchBid ">{{text}}<span class="price">{{Nft_sku.buyoutPrice}}</span>{{unit}}</span> -->
             <h3 class="content mt-10" v-if="purchBid">
              {{ $t("DoNotAuction") }}
            </h3>
            
            <!-- <span class="minprice"> {{ buyoutPrice }} {{$walletUnit}}</span> -->
            <v-text-field
              ref="fixPrice"
              v-model="fixPrice"
              :rules="[ rules.Bid]"
              outlined
              :suffix="unit"
              type="input"
              class="textinput"
              style="margin-top: 8px"
              v-if="!isFix"
            ></v-text-field>
            <v-text-field
              v-model="fixPrice"
              outlined
             :suffix="unit"
              type="input"
              class="textinput"
              style="margin-top: 8px"
              readonly
              v-if="isFix"
            ></v-text-field>
          </div>
          <div></div>
        </div>
      </div>
      <div class="footer mt-5">
        <button class="sub v-btn uc" :class="{ 'sub-dis': isPay }" v-if="isLoad">
          {{ $t("loadBalance") }}
        </button>

        <button class="sub v-btn uc" :class="{ 'sub-dis': isPay }" submit @click="subCommit" v-else-if="isBalance &&  Nft_sku.owner != this.$store.state.did">
          {{ $t("popupTransferCommit") }}
        </button>
         <button class="subdefect" :class="{ 'sub-dis': isPay }" submit  v-else-if="isBalance &&  Nft_sku.owner == this.$store.state.did">
          {{ $t("popupTransferCommit") }}
        </button>
        <button class="subdefect" v-else-if="!isBalance" disabled="disabled">
          {{ $t("popupAuctionDefectPriceInsufficientFunds") }}
        </button>

        <span class="price">{{ $t("popupNewTransferBalance") }}：{{ amountFormat }} {{ Nft_sku.tokenType }} </span>
        

      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <PromptBox ref="promptBox"></PromptBox>
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
import PromptBox from "@/components/PromptBox.vue";
import {getFeePrice} from "../../utils/helper";
import api from "@/api";
 let mount ;
export default {
  components: { PromptBox },
  data: function() {
	  return{
      hash:'',
    open: true,
    fixPrice: "",
    isFix: false,
    userBalance: "",
    buyoutPrice: "",
    isShowFalse: false,
    falseTitle: "",
    text:'',
    unit:'',
    isBalance:false,
    isguarant:false,
    lastBid:false,
    purchBid:false,
    showMessage:false,
    isPay: false,
    price:'',
    isLoad:false,
    feePrice:'',
    totalPrice:'',
    needToShowLink:false,
    amountFormat:'',
    lockValue:'',
    userInfo:{},
    chainToken:'',
    lockHash:'',
    tradeCost:'',
    loopringLink:'',
    StorageId:'',
    closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
    rules: {
      // BidRules: (value) => !!value || "Place a bid is required.",
      Bid: (value) => {
		const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || this.$t("PleaseInPutBid");
      },
    },
	}
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch:{
   

  },

  props: {
    auctionNo: {
      type: String,
    },
    Nft_sku: {
      type: Object,
    },
    buyType: {
      type: Number,
    },


  },
  async mounted() {
    console.log("wxl --- ",this.buyType)
    this.isLoad = true;
    if(this.Nft_sku.auction.guaranteedPrice != null)
    {
      this.isguarant = true
    }
    let text = this.$t("popupAuctionDefectPriceYouMust")

    // this.text = text.substring(0,text.length-3) + price + " "+"IRIS"
    let type = this.buyType;
    if(type == 0){
        this.lastBid =true
    }else{
            this.purchBid = true
       this.lastBid =false
    }
    this.isLoad = true
    this.isPay = true
  
    if (type == 0) {
      this.buyoutPrice = this.Nft_sku.lastBidPrice;
      // this.price = this.Nft_sku.lastBidPrice*1.05;
      this.price = parseFloat(Math.ceil((this.Nft_sku.auction.lastBidPrice)*1.05*10000))/10000
       //this.text = text.substring(0,text.length-3) + this.Nft_sku.lastBidPrice + " "+"IRIS"
      //this.fixPrice = this.buyoutPrice
      this.text = text.substring(0,text.length-3)
       this.unit =  " "+this.Nft_sku.tokenType
      this.isFix = false;
      this.showMessage = true;
      let price;
       price = Number(this.price);

    // let mount = localStorage.getItem("key_balance");
    this.chainToken = this.getChainTokenId(this.Nft_sku.tokenType);
  // 获取余额
  // let res = await this.$wallet.getMyBalance();
    
  // this.amountFormat = mount

  // 获取余额

    if(this.Nft_sku.marketTokenAddress == '0x0000000000000000000000000000000000000000'){
      
          let res = await this.$wallet.getMyBalance();
        mount= res.format
        this.amountFormat= res.format;
    
    }else{
         this.$wallet.setContractAddress('', {
            token20Address: this.Nft_sku.marketTokenAddress,
           
          });
          let balance = await this.$wallet.getTokenBalance(
           this.$store.state.did
          );
          mount= balance
          this.amountFormat = balance
          console.log("wxl ---- ATOM banance",balance);
        
    }

    if(!mount || mount=="null" || mount=="undefined") mount = 0;
    debugger
    this.userBalance = mount;
        if (mount >=price) {
                this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
                this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }
    } else {

      this.buyoutPrice = this.Nft_sku.auction.buyoutPrice;
       //this.text = text.substring(0,text.length-3) + this.Nft_sku.buyoutPrice + " "+"IRIS"
       this.text = text.substring(0,text.length-3)
       this.unit =  " "+this.Nft_sku.tokenType
       this.isFix = true;
      this.fixPrice = this.buyoutPrice;
      this.showMessage = false
      
    

    // let mount = localStorage.getItem("key_balance");
     this.chainToken = this.getChainTokenId(this.Nft_sku.tokenType);
 // 获取余额

    if(this.Nft_sku.marketTokenAddress == '0x0000000000000000000000000000000000000000'){

          let res = await this.$wallet.getMyBalance();
        mount= res.format
        this.amountFormat= res.format;

    
    }else{

            this.$wallet.setContractAddress('', {
            address: this.Nft_sku.marketTokenAddress,
           
          });
          let balance = await this.$wallet.getTokenBalance(
           this.$store.state.did
          );
          mount= balance
          this.amountFormat = balance
          console.log("wxl ---- ATOM banance",balance);
        
    }
    if(!mount || mount=="null" || mount=="undefined") mount = 0;
    this.userBalance = mount;


    //价格
      //this.fixPrice = this.priceSecond;
        let price;
        price = Number(this.fixPrice);
        if (mount >=price) {
                this.isPay = false
        this.isLoad =false
          this.isBalance = true;
        } else {
                this.isPay = false
        this.isLoad =false
          this.isBalance = false;
        }

    }
    this.isLoad = false;
    
  },
  methods: {
            toDecimal(x) { 
 var f = parseFloat(x); 
 if (isNaN(f)) { 
 return; 
 } 
 f = Math.round(x*100)/100; 
 return f; 
 },
 maxDecimal(number){
  return String(number).replace(/^(.*\..{4}).*$/, "$1")
},

    verify() {
      let fixVer = this.$refs.fixPrice.validate(true);
      !fixVer ? this.$refs.fixPrice.focus() : "";
      return fixVer;
    },
  
    //提交转送数据
    async subCommit() {

      if(this.lastBid)
      {
          if (!this.verify()) {
        return;
      }
      }
      this.isPay = true
      if (this.buyType == 0) {
        let fixPrice = Number(this.fixPrice);
     
        if (fixPrice <  this.price ) {

          this.isShowFalse = true;
          this.falseTitle = this.$t("LowerThanCurrentPrice");
          this.isPay = false
          return;
        }
		
		if (fixPrice>this.amountFormat) {
		
		  this.isShowFalse = true;
		  this.falseTitle = this.$t("NotEnoughFee");
		  this.isPay = false
		  return;
		}
			
      }
      try {
         
        let result;
      debugger
      if (this.$chainName == "UPTICK EVM") {
          this.$wallet.setContractAddress(this.Nft_sku.nftType, {
            address: this.Nft_sku.nftAddress,
            platformAddress:this.Nft_sku.marketAddress,
            token20Address:this.Nft_sku.marketTokenAddress
          });
         
          result = await this.$wallet.auction_placeBid(
            this.Nft_sku.nftType,
            this.Nft_sku.nftAddress,
            this.Nft_sku.nftId,
            this.fixPrice,
            this.Nft_sku.marketTokenAddress,
            this.Nft_sku.owner

          );
          console.log("wxl ---- hash",result);
        } 
         this.hash = result.hash
        if(!result.hash){
           this.isPay = false
           this.$toast("error",this.$t("requireFailed"))
           return
        }
        
        if (result.hash) {
   
            if(this.buyType == 1){
               let orderinfo = {
             "marketPrice":this.Nft_sku.auction.buyoutPrice,
             "creatorName":this.Nft_sku.creatorName,
             "creator":this.Nft_sku.creator,
             "NftName":this.Nft_sku.name,
             "hash":this.hash,
             "tokenType":this.Nft_sku.tokenType
           }
             this.$emit("getOpen", false,orderinfo);
            }else{
               this.$emit("getOpen", false);
            }
         
             this.isPay = false
           
        //     let title=''
        //       if(this.buyType == 1){
        //           let t = this.$t("buySuccess")
				// 	let reg=new RegExp('【N】','g')//g代表全部
				// 	let regN=new RegExp('<br/>','g')//g代表全部
				// 	let name = "【" + this.Nft_sku.name + "】"
        //    title=t.replace(reg,name).replace(regN," ");
        //     this.$router.push({name:'Marketplace'})
                  
        //       }else{
        //         title = this.$t("Bid placed")
        //       }
        //        let isShowTip = localStorage.getItem("isShowTip_"+this.$store.state.did)
        //         if(isShowTip == 'true'){
        //             setTimeout(()=>{
        //                 this.$mtip({
        //                 title:title,
        //               });
        //         },2000)
        // }
        } else {
        this.$toast("error", this.$t(result.msg))
        this.isPay = false
        }
      } catch (e) {
        this.$toast("error", this.$t("putawayFailed"))
        this.isPay = false
      }
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
 ::v-deep .v-messages__message {
    color: red;
  }
img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

.card {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: column;
    margin-left: 52px;
    margin-right: 52px;

    .title {
      margin: 42px 0 0 0;
      width: 300px;
      height: 21px;
      font-weight: bold;;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      font-weight:bold
    }

    .content {
      margin: 10px 0 15px 0;
      width: 485px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
    }
    .contentPrice{
      margin: -10px 0 20px 0;
      width: 485px;
      height: 62px;
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #766983;
      word-wrap: break-word;
      width: 100%;
    }

    .form {
      .ti-in {
        .titles {
          margin-top: 24px;
          width: 152px;
          height: 13px;
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 48px;
          letter-spacing: 0px;
          color: #270645;
        }
        .price{
          font-weight:bold

        }

        .minprice {
          width: 51px;
          height: 13px;
          font-weight: bold;;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }

        .textinput {
          //  width: 500px;
          height: 55px;
          // background-image: linear-gradient(#ffffff, #ffffff),
          //   linear-gradient(#f8f6fd, #f8f6fd);
          // background-blend-mode: normal, normal;
          border-radius: 5px;
      //       &::v-deep .v-text-field__slot input {
      //   color:#272727 !important;

      // }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      color: #766983;
    }

    .sub {
      margin: 20px auto;
      width: 450px;
      height: 51px;
      background:#7800f4;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
     .subdefect {
      margin: 20px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      margin: 0 10%;

      .content {
        width: 90%;
        height: auto;
      }
      .form {
        .ti-in {
          width: 100%;
          .textinput {
            width: 100%;
          }
        }
      }
    }

    .footer {
      .sub {
        width: 80%;
      }
    }

    .contentPrice {
      height: unset;
    }
  }
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 200px;
    margin-top: 3px;
    
    // box-shadow: 0 0 10px #fff;
    // border-radius: 50%;
}
</style>




