<template>
  <div :class="{ mobile: isMobile, darkBackground1: this.$vuetify.theme.dark }" style="display: flex;flex-direction:row;justify-content: space-between;width: 100%;">
   
    <div class="fullWith " :class="{notFull:currentProductImage}">
      <v-card-title class="d-block text--title pt-4  px-0 pb-2 text-truncate"
      :class="{ darkFont: this.$vuetify.theme.dark }">{{ value.name ? value.name : "No Name" }}</v-card-title>
    <v-card-text class="d-flex flex-row align-center pb-0  px-0 text-avatar">
    
      <div class=" description"  :class="{ darkFont: this.$vuetify.theme.dark }">
       {{value?.previewDescription}}</div>
    </v-card-text>
    <v-card-text class="d-flex flex-row  px-0 pt-3 pb-4 text-number">
      <div>
        <v-img :src="getChainByName(value.payTokenType).img" alt="logo" width="22px" height="22px" />
      </div>
      <div class="ml-2 text--number text--primary-rd font-weight-medium" style="line-height:22px;"   :class="{ darkFont: $vuetify.theme.dark }">
       {{value.payAmount}}
      </div>
    </v-card-text>
    </div>
    <div  v-if="isMobile && currentProductImage">
      <img :src="currentProductImage"  alt="logo" width="86" height="86"  class="imgUrl"/>
    </div>
    <div  v-if="!isMobile && currentProductImage">
      <img :src="currentProductImage" alt="logo" width="86" height="86"  class="imgUrl"/>
    </div>
   
  </div>
</template>

<script lang='js'>

import api from '@/api'
export default {
  name: 'SellCardContent',
  components: {  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => { }
    },
    isMinPrice: {
      type: String
    }
  },
  data: function () {
    return {
      images:[],
      currentProductImage:''
      // countLogo
    };
  },

    mounted(){
      if(this.value.previewImgUrls){
                JSON.parse(this.value.previewImgUrls).forEach(  element => {
                let imgUrl={imageUrl:  api.image.getNftImg(element)}
                this.images.push(imgUrl);

      })
      if(this.images){
        this.currentProductImage=this.images[0].imageUrl;
      }
  }
},
  
};

</script>

<style lang='scss' scoped>
.darkBackground1 {
  background-color: rgba(18,18,19,1)
  
}
.fullWith{
  width: 100%;
}
.notFull{
  width: 60%;

}

.text--primary-rd {
  color: #270645;
}

.text--secondary-rd {
  color: #766983;
}

.text--title {
  font-size: 13px;
  line-height: 24px;
  font-weight: bold;

  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.description{
  height: 30px;
  font-size: 13px;
  line-height: 15px;
  color: #766983;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis
}

.text--sub-title {
  font-size: 12px;
  line-height: 1;
}

.text--number {
  font-size: 15px;
  line-height: 1;
  font-weight: bold !important;
}

.v-divider {
  border-color: #e3e3e3;
}
.imgUrl{
  margin-top: 23px;
  border-radius: 10px;
  object-fit: cover;

}
.mobile{
  display: flex;
  flex-direction: row !important;

  .notFull{
    width: 100%;
  }
  .imgUrl{
    width: 86px !important;
  

  }
}
</style>
