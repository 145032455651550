import {
    connect,
	connectCheck,
	wallectConnectSendTransaction,
	isWalletConnect
} from "./common";
import {
	stringToBytes32
} from "@/utils/getHash"


import {
    abi
} from "../artifact/LazyNFT1948.json";
import { utils } from "ethers";

const base = require('./base');

//xxl todo get from .evn
let contractAddress = ""

export function setContractAddress(address) {
console.log("初始化合约",address);
    if(address) {
        contractAddress = address;
    }
   
}



export async function lazyMint( toAddress,tokenId,baseurl,payAddress,payAmount,creatorFee,signature,data,fee) {
     const account = await base.getAccounts();
    const fromAddress = await account.getAddress();

    let contract
    if (!contract) {
        contract = await connect(contractAddress, abi, account);
    }
	

	let hasWalletConnect = isWalletConnect();
	if(!hasWalletConnect){
		let gasSetting = await base.getGasPriceAndGasLimit();
		console.log("gasSetting 1155", gasSetting);
		console.log("货品卡懒铸造参数",   toAddress, tokenId, baseurl,payAddress, payAmount,creatorFee,signature,stringToBytes32(data),);
		console.log("货品卡懒铸造参数fee 888", signature,stringToBytes32('abc'));
		let result = await contract.lazyMint(
		    toAddress, tokenId, baseurl,payAddress, payAmount,creatorFee,signature,stringToBytes32(data),
		    { value:fee,gasPrice: gasSetting.gasPrice, gasLimit: gasSetting.gasLimit }
		);
		return result;
		
	}else{
		  let data= contract.methods.lazyMint(toAddress, tokenId, baseurl,payAddress, payAmount,creatorFee,signature,stringToBytes32(data)).encodeABI();
		let result = await wallectConnectSendTransaction(fromAddress,contractAddress,data,payAmount);
		return result;
		 
	}
	


	
	
}

