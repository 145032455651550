<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
        <div class="d-flex flex-row">
          <h3 :class="{darkFont:$vuetify.theme.dark }">{{ $t("headerNavAuctions") }}</h3>
          <img
            class="rule"
            @click="openRules"
            src="@/assets/icons/icon_wen.png"   
            alt=""
            title="Viewing auction rules"
          />
        </div>
        <h3 class="pupils" :class="{darkFont:$vuetify.theme.dark }">{{ name }}</h3>
      </div>
       <div class="form">
        <div class="title " :class="{darkFont:$vuetify.theme.dark }">Token</div>
        <ChainSelectInput @change="chainChange" ></ChainSelectInput>
      </div>
      
      <div class="form mt-5">
        <div class="ti-in">
          <span class="title" :class="{darkFont:$vuetify.theme.dark }">{{ $t("auctionStartBid") }}</span>
          <span class="subtitle">{{ $t("BidsBelow") }}</span>
          <v-text-field
          class="text-file"
           ref="startBid"
            v-model="startBid"
            outlined
            :suffix="chain"
            :rules="[rules.errorPrice]"
            type="input"
            onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
            maxlength="10"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title" :class="{darkFont:$vuetify.theme.dark }">{{ $t("auctionReservePrice") }}</span>
          <span class="subtitle">{{ $t("afterTheAuction") }}</span>
          <v-text-field
           ref="ReserveBid"
            v-model="ReserveBid"
            outlined
            :suffix="chain"
            type="input"
             :rules="[rules.errorPrice1]"
             onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
            maxlength="10"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title" :class="{darkFont:$vuetify.theme.dark }">{{ $t("popupAuctionFixedPrice") }}</span>
          <span class="subtitle">{{ $t("buyoutPriceNotice") }}</span>
          <v-text-field
             ref="fixPrice"
            v-model="fixPrice"
            outlined
           :suffix="chain"
            type="input"
             :rules="[rules.errorPrice1]"
           onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, '$1')"
            maxlength="10"
          ></v-text-field>
        </div>
      </div>
      <div class="form">
        <div class="ti-in">
          <span class="title mb-n3" :class="{darkFont:$vuetify.theme.dark }" >{{ $t("popupAuctionStartDate") }}</span>
          <v-datetime-picker label="" v-model="datetime" :class="{darkFont:$vuetify.theme.dark }">
            <template v-slot:dateIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                :src="isDark  ? darkCalIcon:calIcon"
                alt
              />
            </template>
            <template v-slot:timeIcon>
              <img
                style="
                  display: inline;
                  width: 24px;
                  height: 24px;
                  margin: 0;
                  background-color: transparent;
                "
                :src='isDark ?darkClockIcon:clockIcon'
                alt
              />
            </template>
          </v-datetime-picker>
        </div>
      </div>
      <div class="form mt-lg-3">
        <div class="ti-in">
          <span class="title" :class="{darkFont:$vuetify.theme.dark }">{{ $t("popupAuctionEndDate") }}</span>
          <v-select
            :class="{select:$vuetify.theme.dark}"
            :items="list"
            :label="this.$t('auctionTime')"
            v-model="TimeValue"
            item-text="text"
            item-value="value"
            solo
            any
          ></v-select>
          <span class="subtitle">{{ $t("ruleLast") }}</span>
        </div>
      </div>

      <div class="footer">
      
     
        <button class="sub" :class="{'sub-dis': isPay}"  @click="subCommit">{{
          $t("popupTransferCommit")
        }}</button>
     
      </div>
    </v-card>
    <v-snackbar
      v-model="isShowFalse"
      color="error"
      centered
      timeout="2000"
      style="opacity: 0.8"
      ><span style="color: white; font-size: 20px">{{
        falseTitle
      }}</span></v-snackbar
    >
    <PromptBox ref="promptBox"></PromptBox>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import { addDate,addDateToSecond } from "../../../src/utils/helper";
import api from "@/api";
import PromptBox from "@/components/PromptBox.vue";
import ChainSelectInput from "@/components/ChainSelectInput.vue";
import Web3 from "web3";
import Button from '@/views/detail/components/Button.vue';
const web3Obj = new Web3();

const { toShowValue,tokenId2Index} = require("../../utils/helper");


export default {
  name: "transfer",
  components: { PromptBox,ChainSelectInput, Button },
  data: () => ({
    calIcon:require('@/assets/icons/icon-calendar.png'),
    darkCalIcon:require('@/assets/darkimage/icon_rili.png'),
    clockIcon:require('@/assets/icons/icon-clock.png'),
    darkClockIcon:require('@/assets/darkimage/icon_sj.png'),
    isDark:false,
    value1:'',
    open: true,
    startBid: "",
    fixPrice: "",
    switch1: "false",
    defaultFee: "0.1",
    userBalance: "",
    TimeValue: 1,
    ReserveBid: "",
    isShowFalse: false,
    falseTitle: "",
    isPay: false,
    datetime: "",
    currentMinPrice:'',
    currentMaxPrice:'',
    lockHash:'',
    userInfo:{},
     chainName:'LRC',
     royaltyPercentage:"",
     totalRoyalty:"",
      closeIcon:require('@/assets/btn_close.png'),
      darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
    rules: {
      errorPrice:(value) => {
        // if(value==0){
        //   return "Invalid"; 
        // }
        //  const pattern =  /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,8})?$/;
        // return pattern.test(value) || "Invalid";
        const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || "Out of price range";
       },
       errorPrice1:(value) => {
        if(value == ''){
          return true; 
        }
        //  const pattern =  /^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,8})?$/;
        // return pattern.test(value) || "Invalid";
        const pattern = /^((\+?[0-9][0-9]{0,8})\.([0-9]{1,4})$)|^(\+?[0-9][0-9]{0,8})$/;
        return pattern.test(value) || "Out of price range";
       },
       
      
    },
      chain:'UPTICK',
  chainAddress:'0x0000000000000000000000000000000000000000',
    auctionPrice:''
  }),
  props: {
    address: {
      type: String,
    },
    onSaleTokenIds: {
      type: Array,
    },
    offSaleTokenIds: {
      type: Array,
    },
    name: {
      type: String,
    },
    parentValue: {
      type: Object,
    },
  },
  created() {
    this.datetime = new Date();
  },
  // watch:{
  //   startBid(startBid){
        
  //       if(startBid > 100){
  //       this.startBid =100
  //     }
    
  //   },
  //   ReserveBid(ReserveBid){
  //     if(ReserveBid > 100){
  //       this.ReserveBid =100
  //     }
  //   },
  //   fixPrice(fixPrice){
  //       if(fixPrice > 100){
  //       this.fixPrice =100
  //     }

  //   }
  // },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
      list:function () {
      return  [
          {
            text: this.$t('auctionTime'),
            value: 1,
          },
          {
            text: this.$t('auctionTime48'),
            value: 2,
          },
          {
            text: this.$t('auctionTime72'),
            value: 3,
          },
        ];
      
    }
  },
  async mounted() {
      let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
       let currentSymbol = `${this.$env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL}` 
      if(currentChain == 'POLYGON'){
        this.chain = 'MATIC'
      }else if(currentChain == 'BSC'){
        this.chain = 'BNB'
      }else if( currentChain == 'ARBITRUM'){
         this.chain = currentSymbol
    }
    
          // 判断是否为黑暗模式
        let isDarkMode =  localStorage.getItem('isDarkMode');
        if(isDarkMode){
             if(isDarkMode == 'true'){
              this.isDark = true
           }else{
             this.isDark = false 
           } 
        }else{
            this.isDark = false
        }
        window.eventBus.$on("DarkModeChange", this.DarkModeChange);
  
      
  },
  methods: {
    DarkModeChange(value){
        this.isDark = value
     },
    verify() {
      let saleVer = this.$refs.startBid.validate(true);
      let fixVer = this.$refs.fixPrice.validate(true);
      let ReserveVer = this.$refs.ReserveBid.validate(true);
  
      !saleVer ? this.$refs.startBid.focus() : "";
      !fixVer ? this.$refs.fixPrice.focus() : "";
      !ReserveVer ? this.$refs.ReserveBid.focus() : "";
      return saleVer && fixVer && ReserveVer;
    },
    async chainChange(chain,coinname) {

          console.log("wxl --- 00000",chain,coinname)
         this.chain = coinname
         this.chainAddress = chain.address
        
        },
    //提交转送数据
    async subCommit() {
      try {
        if (!this.verify()) {
          return;
        }
        if (!Number(this.startBid) || Number(this.startBid) <= 0) {
          this.$toast("error", this.$t("LowerThanStartBid"));
          return;
        }
        if (Number(this.fixPrice) != "") {
          this.startBid = Number(this.startBid)
         this.fixPrice = Number(this.fixPrice)
          if (Number(this.startBid) >= Number(this.fixPrice)) {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }
        }
        if(Number(this.ReserveBid) != '' && Number(this.fixPrice) == '')
        {
          this.startBid = Number(this.startBid)
         this.ReserveBid = Number(this.ReserveBid)
          if(Number(this.startBid) >= Number(this.ReserveBid) )
          {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }

        }
        if(Number(this.ReserveBid) == '' && Number(this.fixPrice) != '')
        {
          this.startBid = Number(this.startBid)
         this.fixPrice = Number(this.fixPrice)
          if(Number(this.ReserveBid) >= Number(this.fixPrice) )
          {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
          }


        }
        if(Number(this.ReserveBid) != '' && Number(this.fixPrice) != '')
        {
         this.startBid = Number(this.startBid)
         this.ReserveBid = Number(this.ReserveBid)
         this.fixPrice = Number(this.fixPrice)
         if(   Number(this.startBid) >= Number(this.ReserveBid) ||
          Number(this.ReserveBid) >= Number(this.fixPrice))
        {
            this.$toast("error", this.$t("auctionPriceOnsaleError"));
            return;
        }

        }

    if(Number(this.ReserveBid) != ''){
       this.$gtag.event('拍卖上架-设置Reserve price', { 'event_category': 'Click', 'event_label': '设置Reserve price' })
    }
    if(Number(this.fixPrice) != ''){
       this.$gtag.event('拍卖上架-设置Buy it now price', { 'event_category': 'Click', 'event_label': '设置Buy it now price' })
    }


           
		
		  
        if (new Date() - this.datetime > 3600000) {
          this.datetime = null;
          this.$toast("error", this.$t("LowerThanCurrentTime"));
          return;
        }
       
         this.isPay = true
       // 拍卖上链
        let startTimeStamp = parseInt(this.datetime.valueOf()/1000);
        let endTimeStamp = addDateToSecond(startTimeStamp, this.TimeValue);
        console.log("wxl --- wwwww",startTimeStamp,endTimeStamp)
       let result
        let res = await api.home.getFee();
        if(this.parentValue.nftType=="ERC721" || this.parentValue.nftType=="ERC1948") {
      this.value1=Number(res.data.erc721OnsaleUptickAssetFee);
    }
    else if(this.parentValue.nftType=="ERC1155") {
      this.value1=Number(res.data.erc1155OnsaleUptickAssetFee);
    }
          if (this.$chainName == "UPTICK EVM") {
          this.$wallet.setContractAddress(this.parentValue.nftType, {
            address: this.parentValue.nftAddress,
            platformAddress: (this.parentValue.nftType == 'ERC721' || this.parentValue.nftType == 'ERC1948')? res.data.erc721AuctionPlatform : res.data.erc1155AuctionPlatform ,
          });
          // let vvv = this.parentValue;
          // console.log(vvv);
          result = await this.$wallet.auction_onsale(
            this.parentValue.nftType,
            this.parentValue.nftAddress,
            this.parentValue.nftId,
            startTimeStamp,
            endTimeStamp,
            this.startBid,
            this.fixPrice ? this.fixPrice: 0,
            this.ReserveBid ? this.ReserveBid : 0, 
            this.value1,
            this.parentValue.amount,
            this.chainAddress
          );
          console.log("wxl ---- hash",result);
        }  
            
        if(!result.hash){
           this.isPay = false
           this.$toast("error",this.$t("requireFailed"))
           return
        }

          let isDoingParams = {
          nftAddress: this.parentValue.nftAddress,
          nftId:this.parentValue.nftId,
          hash:result.hash,
          owner:this.$store.state.did,
          saleStatus:6,
          marketType:2
        };
     
        let isding = await api.home.isDoing(isDoingParams);
    
   
    if(isding.success == true)
    {
       await this.sleep(5000).then(()=>{
        window.eventBus.$emit("backgroundUpdate");
      this.isPay = false;  
        this.$emit("getOpen", false,'onSaleSuccess')
   
    
   })
      // this.$toast("success", this.$t("success")).then(() => {
      //   this.$emit("getOpen", false)
      //        this.isPay = false
      //     });  
      //      window.eventBus.$emit("collectionPay");
    }else{
       this.$toast("error", this.$t("transactionInprogressError"));
         this.isPay = false
    }
   }
       catch (e) {
         console.log("wxl --- error",e)
        this.$toast("error", this.$t("transactionInprogressError"));
         this.isPay = false
      }
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    closeDialog() {
      console.log("wxl ---- closeDialog" )
      this.open = false;
      this.$emit("getOpen", this.open);
    },
    openRules() {
      this.$gtag.event('拍卖上架-拍卖规则', { 'event_category': 'Click', 'event_label': '拍卖规则' })
      if (this.$vuetify.lang.current == "en") {
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_en",
          query: { id: 96 },
        });
        window.open(routeUrl.href, "_blank");
      } else if(this.$vuetify.lang.current == "zh"){
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_zh",
          query: { id: 97 },
        });

        window.open(routeUrl.href, "_blank");
      }
    else if(this.$vuetify.lang.current == "ja"){
        let routeUrl = this.$router.resolve({
          path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }
      else if(this.$vuetify.lang.current == "ko"){
        let routeUrl = this.$router.resolve({
         path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }else if(this.$vuetify.lang.current == "pt"){
        let routeUrl = this.$router.resolve({
         path: "/auctionrule_en",
          query: { id: 96 },
        });
        
        window.open(routeUrl.href, "_blank");
      }
    },
     onLangChange() {
       this.list= [
          {
            text: this.$t('auctionTime'),
            value: 1,
          },
          {
            text: this.$t('auctionTime48'),
            value: 2,
          },
          {
            text: this.$t('auctionTime72'),
            value: 3,
          },
        ];
     }
  },
};
</script>

<style lang="scss" scoped>
.rule {
  width: 28px;
  height: 28px;
  display: inline-block;
  margin-top: 48px;
  margin-left: 15px;
  cursor: pointer;
}
.card {
  display: flex;
  flex-direction: column;
  min-height: 748px;
  width: 600px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: column;
    margin-top:-20px;

    h3 {
      margin: 41px 0 24px 52px;
      font-weight: bold;;
      font-size: 25px;
      color: #270645;
    }

    .pupils {
      margin: 0 0 18px 51px;
      font-weight: bold;;
      font-size: 25px;
      color: #270645;
    }
  }

  .form {
    margin: -8px 52px;
    display: flex;
    flex-direction: column;
    
    .text-file{
      &::deep  .error--text{
      color: blue!important;
    }
    }
    .select{
       &::v-deep .v-select__selection--comma{
      color: #fff !important;
    }
   
    }
   
   
  }
   .showLimit{
      margin-left: 52px;
  margin-top:20px;
  margin-bottom: -10px;
      .limintContent{
      font-weight:bold;
}
    }

  .footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
   

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      /* line-height: 40px; */
      color: #766983;
    }

    .sub {
      margin: 14px auto;
      width: 450px;
      height: 51px;
       background-color: #7800f4;
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
  }
  &.mobile {
    .top {
      margin-left: -12px;
      .pupils {
        width: 90%;
        margin: 0 0 18px 52px;
        word-wrap: break-word;
      }
    }
    .form {
      margin-left: 40px;
    }

    .footer {
      margin-left: -12px;
      .sub {
        width: 80%;
      }
      
      .showLimit {
        margin-left: 50px;
        flex-direction: column !important;
        .maxTxt {
          margin-left: 0px !important;
        }
      }
    }

  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}

span {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #270645;
}

.title {
  font-weight: bold!important;
  font-size: 15px!important;
  font-weight: normal;
  font-stretch: normal;
  color: #270645;
}
.subtitle {
  font-family: Helvetica;
  font-size: 15px;
  color: #766983;
  margin-top:-5px;
}
.sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(
      #766983, 
      #766983), 
     linear-gradient(
      #270645, 
      #270645) !important;
     background-blend-mode: normal, 
      normal;
     border-radius: 25px;
     opacity: 0.9;
}
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
   margin-left: 10px;
  margin-top: 5px;
}
  ::v-deep .v-messages__message {
    color: red;
  }
</style>
