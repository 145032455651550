<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img class="clo" @click="closeDialog" :src="$vuetify.theme.dark?darkcloseIcon:closeIcon" alt="" />
      <div class="top">
        <h3 :class="{darkFont:$vuetify.theme.dark }">{{ $t("popupNewTransferTransfer") }}</h3>
        <div class="content d-flex flex-lg-row flex-column">
          <img :src="Imgsrc" alt="" style="background-color: #fff" />
          <div class="rgh ml-12">
            <h4 :class="{darkFont:$vuetify.theme.dark }">{{ this.parentValue.name }}</h4>
            <div class="ic-sp" v-if="islevel">
              <img src="@/assets/bbb.png" alt="" class="icon" />
              <span>{{ $t("popupNewTransferPyroworld") }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form">
        <div class="ti-in mt-n3">
          <div class="d-flex flex-row justify-space-between" style="  position: relative;">
            <div class="title" :class="{darkFont:$vuetify.theme.dark }">{{ $t("popupTransferReceiver") }}</div>
            <div class="Contacts" @click="openDialog">{{ $t("Contacts") }} ></div>
          </div>
          
          <!-- <v-select v-model="selectValue" :items="items" placeholder="Please Select" label="" item-text="text"
            item-value="value" outlined></v-select> -->
          <!-- 转送自己隐藏备注框 -->
          <v-text-field v-if="selectValue === 2" v-model="firstname" ref="addressValue"
            :placeholder="this.$t('popupTransferInput')" :rules="[rule.addressRules]" outlined type="input" clear-icon="mdi-close-circle" clearable >
          </v-text-field>
          <div class="avatar d-flex flex-row align-center mb-7" v-if="userContrctInfo != null && firstname">
            <Avatar
            style="margin: 0 auto"
            :size="45"
            :hash="userContrctInfo.profilePhoto"
            :grade="userContrctInfo.grade"
			      imgType="middlev"
            :did="userContrctInfo.bscAddress"
            showAvatarInfo
          />
            <div class="contaceName ml-2" :class="{darkFont:$vuetify.theme.dark }"> {{userContrctInfo.name ? userContrctInfo.name :userContrctInfo.address.substr(0, 10)+"..."+userContrctInfo.address.substr(-10)}} </div>
            <div class="mark ml-1" v-if="userInfo.remark">{{'('+userInfo.remark+')'}}</div>
          </div>
        </div>
        <div class="ti-tx mt-n1" v-if="selectValue === 2">
          <span class="title" :class="{darkFont:$vuetify.theme.dark }">{{ $t("popupNewTransferRemarks") }}</span>
          <v-textarea label="" v-model="firsttxt" auto-grow outlined rows="2" row-height="18"></v-textarea>
        </div>
      </div>
      <div class="count d-flex flex-column flex-lg-row">
        <div>
          <span class="title" :class="{darkFont:$vuetify.theme.dark }">{{ $t("popupNewTransferAmount") }}</span>
          <span :class="{darkFont:$vuetify.theme.dark }">{{ $t("balanceCount") }}:{{ parentValue.amount }}</span>
        </div>
        <div class="countrgh d-flex flex-row mr-lg-n5 mt-lg-0 mt-5 mb-n5">
          <v-btn class="counticon" small @click="reduceBtn" :class="{somberBackground:$vuetify.theme.dark }">
            <v-icon dark>mdi-minus</v-icon>
          </v-btn>
          <!-- <span class="countinput">{{ countValue }}</span> -->
          <!-- <input class="countinput" type="text" v-model.number="countValue" oninput="value=value.replace(/\D|^0/g,'')" /> -->
          <v-text-field class="countinput" :rules="[rule.editionCount]" ref="countValue" v-model="countValue" oninput="value=value.replace(/\D|^0/g,'')"/>
          <v-btn class="counticon" small @click="increaseBtn" :class="{somberBackground:$vuetify.theme.dark }">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="footer">
       <!-- <button class="sub v-btn uc mt-3" :class="{ 'sub-dis': isPay }" v-if="isLoad">
          {{ $t("loadBalance") }}
        </button> -->
         <button class="sub v-btn uc mt-3" :class="{ 'sub-dis': isPay }" submit @click="subCommit" v-if="!needToShowLink && countValue <= this.parentValue.amount ">
          {{ $t("popupTransferCommit") }}
        </button>
         <button class="subdefect  mt-3" :class="{ 'sub-dis': isPay }" submit @click="subCommit" v-else-if="!needToShowLink && countValue > this.parentValue.amount ">
          {{ $t("popupTransferCommit") }}
        </button>
        <button class="subdefect mt-3" v-else-if="needToShowLink" disabled="disabled">
          {{ $t("popupAuctionDefectPriceInsufficientFunds") }}
        </button>

        <span class="price mt-2">{{ $t("popupNewTransferBalance") }}：{{ transferValue }} {{chain}} </span>
        
      </div>
    </v-card>
      <v-dialog v-model="openContract" v-if="openContract">
      <ContractList
        @getOpen="openAddContact"
        @userInfo="accectInfo"
      ></ContractList>
    </v-dialog>
    <PromptBox ref="promptBox" @commit="promptBoxCommit" ></PromptBox>
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>
import { getFileSrc } from "@/utils/file";
import api from "@/api";
import PromptBox from "@/components/PromptBox.vue";
import ContractList from "@/components/popup/contractList.vue";
import Avatar from "@/components/avatar/index.vue";

const WORK_KEY = "WORK";
export default {
  components: { PromptBox,ContractList,Avatar },
  name: "newTransfer",

 data: function () {
    return {
      openContract:false,
      newOpen: true,
      items: [
        {
          text: this.$t("ToMyself"),
          value: 1,
        },
        {
          text: this.$t("ReceiverAddress"),
          value: 2,
        },
      ],
      selectValue: 2,
      firstname: "",
      countValue: "1",
      firsttxt: "",
      transferFee: 0,
      userBalance: "",
    subDisabled: false,
     isLoad: false,
	 transferValue: "",
	 lockValue:"",
      islevel: false,
      transferName: "",
      Imgsrc: "",
      loopringLink:"",
      userContrctInfo:null,
      userInfo:{},
      commitType: 0,
      isPay: false,
          chain:'UPTICK',
      needToShowLink:false,
      closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
      rule: {
        addressRules: (value) => {
          let flag = (value.substring(0,2)=="0x" && value.length==42) || (value.substring(value.length-12)=="loopring.eth" && value.length>14) || (value.substring(0,6)=="uptick" && value.length==45);
          if(!flag) {
            return "Address format error";
          }
          return true;
        },
        editionCount: (value) => {
          // const pattern=/^(?!0)(?:[0-9]{1,3}|1000)$/;
          if (value <= 0 || value > this.parentValue.amount) {
            return "Out of amount range";
          }
          return true;
        }
      },
    };
  },

  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch:{
    firstname(value){
      this.getContactInfo(value);
    }
  },
  props: {
    address: {
      type: String,
    },
    imgUrl: {
      type: String,
    },
    level: {
      type: Number,
    },
    name: {
      type: String,
    },
    offSaleTokenIds: {
      type: Array,
    },
    contracId: {
      type: Number,
    },
    parentValue: {
      type: Object,
    },
  },
  async mounted() {
    console.log('====================================');
    console.log(this.parentValue);
    console.log('====================================');
   // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
     let currentSymbol = `${this.$env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL}` 
    if(currentChain == 'BSC'){
     
       this.chain = 'BNB'
    }else if( currentChain == 'POLYGON'){
      
         this.chain = 'MATIC'
    }else if(currentChain == 'ARBITRUM'){
        this.chain = currentSymbol
    
      }

    let res2 = await api.home.getFee();
    if(this.parentValue.nftType=="ERC721") {
      this.transferFee=Number(res2.data.erc721OnsaleUptickAssetFee);
    }
    else if(this.parentValue.nftType=="ERC1155") {
      this.transferFee=Number(res2.data.erc1155OnsaleUptickAssetFee);
    }

    if (this.level == 1) {
      this.islevel = false;
    } else {
      this.islevel = true;
    }
    if (this.imgUrl != null) {
 
      let metadataUrls = this.imgUrl.indexOf('https')

if(metadataUrls == -1){
 
  this.Imgsrc = await getFileSrc(WORK_KEY, this.imgUrl);
}else{
    this.Imgsrc = this.imgUrl
}
    }
    this.transferName = this.name;
    let balance = await this.$wallet.getMyBalance();
    let mount = balance.format;
    this.transferValue = mount;
    this.firsttxt =
      "I sent you【" +
      this.parentValue.name +
      "】，please check it in your collection！";
  },
  methods: {
    verify() {
      debugger
      let key = localStorage.getItem("KEY_CHAIN");
      let addresslVer = this.$refs.addressValue.validate(true);
      !addresslVer ? this.$refs.addressValue.focus() : "";
      if (!addresslVer) {
        if(!this.firstname){
           this.$toast("error", this.$t("addressNotEmpty"));
        }else{
          this.$toast("error", this.$t("invalidAddress"));
        }
       
        return false;
      }
        let countVer = this.$refs.countValue.validate(true);
      !countVer ? this.$refs.countValue.focus() : "";
      //xxl ##04 TODO need to iaa and length

      if(this.chain == 'MATIC'){
          if (
         !(this.firstname.startsWith("0x") &&
          this.firstname.length == 42)
        ) {
          this.$toast("error",this.$t("invalidAddress"));
          return false;
        }
      
    }else{
          if (
         !( (this.firstname.startsWith("0x") &&
          this.firstname.length == 42) || (this.firstname.startsWith("uptick") ||  this.firstname.length==45))
        ) {
          this.$toast("error",this.$t("invalidAddress"));
          return false;
        }
      
    }
    
       
      

      return addresslVer && countVer;
    },
    //提交转送数据
   async getContactInfo(value){
      let param = {
        addressBook:value
      }
   let res =  await api.home.getContractInfo(param)
   if(res?.data){
    this.userInfo = res.data
    this.userContrctInfo = res.data.userBookInfo
   }

    },
    openDialog(){
      console.log("openDialog");
       this.openContract = true
    },
     openAddContact(e){
        this.openContract = e
      },
    async subCommit() {
      debugger
      this.isPay = true;
      console.log("xxl firstname start ....");
      console.log(this.firstname);
      console.log(this.selectValue);
      console.log("xxl firstname end ");
      debugger
      let toAddress
     if(this.firstname.substring(0,6)=="uptick" && this.firstname.length==45){
       toAddress =  this.userContrctInfo.address
     }else{
       toAddress  = this.firstname
     }
     console.log(' this.firstname', this.firstname);

      //2 = send to others
      if (this.selectValue == 2) {
        if (!this.verify()) {
          return;
        }
      }

      try {
   
        let result;
          let res = await api.home.getFee();

		      	console.log(this.parentValue)   
          
			// TODO assetIds如何转换成json
            this.$wallet.setContractAddress(this.parentValue.nftType, {
              address: this.parentValue.nftAddress,
              platformAddress: this.parentValue.nftType == 'ERC721'? res.data.erc721Platform : res.data.erc1155Platform ,
            });
			    console.log(this.parentValue)
            result = await this.$wallet.transferFrom(this.parentValue.nftType, toAddress, this.parentValue.nftId,this.countValue);

            console.log("result",result);
            debugger
            if(!result.hash){
           this.isPay = false
           this.$toast("error",this.$t("requireFailed"))
           return
        }
           
           let isDoingParams = {
          nftAddress: this.parentValue.nftAddress,
          nftId:this.parentValue.nftId,
          hash:result.hash,
          owner:this.$store.state.did,
          saleStatus:8
        };
        let isding = await api.home.isDoing(isDoingParams);
         if (isding.success == true) {
             if (result.hash) {
          // this.$refs.promptBox.show(this.$t("messageTransferSuccess"), 2);
          // this.$toast("success", this.$t("messageTransferSuccess"));
          // this.$emit("getOpen", false);
          // this.isPay = false;
          // setTimeout(() => {
          //   this.$router.push({ name: "PersonalPageCopy" });
          //   sessionStorage.setItem("PersonalPage_Tabs", 1);
          // }, 2000);
        let isShowTip = localStorage.getItem("isShowTip_"+this.$store.state.did)
        if(isShowTip == 'true'){
              this.$mtip({
              title: this.$t("transferSuccess"),
            });
        }
           
          this.$emit("getOpen", false);
          this.isPay = false;
          window.eventBus.$emit("backgroundUpdate");
           window.eventBus.$emit("collectionPay");
        }
         }
        
      
      } catch (e) {
        // this.$refs.promptBox.show(this.$t("messageTransferFailed"), 3);
        this.$toast("error", this.$t("messageTransferFailed"));
        this.isPay = false;
      }
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.newOpen = false;
      this.$emit("getOpen", this.newOpen);
    },
    //增减
    reduceBtn() {
  
      if (this.countValue > 1) {
        this.countValue--;
      }
    },
    increaseBtn() {

      if (this.countValue < this.parentValue.amount) {
        this.countValue++;
      }
    },

    promptBoxCommit(type) {
      this.$emit("promptBoxCommit", type);
    },
    accectInfo(item){
      console.log("item",item);
      this.firstname = item.userBookInfo.address
    }
  },
};
</script>

<style lang="scss" scoped>

.card {
  display: flex;
  flex-direction: column;
  min-height: 590px;
  width: 600px;
  .contaceName{
    font-family: Helvetica;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #270645;
  }
  .mark{
    font-family: Helvetica;
	font-size: 12px;
	font-weight: normal;
	letter-spacing: 0px;
	color: #766983;
  }


  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }

  .top {
    display: flex;
    flex-direction: column;

    h3 {
      margin: 16px 0 19px 20px;
      font-weight: bold;;
      font-size: 25px;
      color: #270645;
    }

    .content {
      display: flex;
      flex-direction: row;

      .rgh {
        display: flex;
        flex-direction: column;

        h4 {
          font-weight: bold;;
          font-size: 20px;
          color: #270645;
          margin-bottom: 10px;
        }

        .ic-sp {
          display: flex;

          .icon {
            display: flex;
            align-items: flex-end;
            width: 41px;
            height: 40px;
            background-color: #6f58d9;
            border-radius: 50%;
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .form {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
  }

  .count {
    margin: -10px 20px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .countrgh {
      // position: absolute;
      // right: 32px;

      .counticon {
        width: 46px;
        height: 45px;
        font-size: 40px;
        margin: 6px 20px;
        background-color: #e3e3e3;
        border-radius: 5px;
        display: inline-block;
      }

      .countinput {
        width: 135px;
        height: 56px;
        text-align: center;
        vertical-align: middle;
        display: inline-block;
        padding: 12px 0;
        border-radius: 5px;
        // border: solid 2px #e3e3e3;
        &::v-deep input {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;

    .price {
      display: block;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      color: #766983;
    }

    .sub {
      margin: 0 auto;
      width: 450px;
      height: 51px;
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      color: #fff;
      font-weight: bold;;
      font-size: 20px;
    }
     .subdefect {
      margin: 10px auto;
      width: 450px;
      height: 51px;
      background-image: linear-gradient(#766983, #766983),
        linear-gradient(#270645, #270645);
      background-blend-mode: normal, normal;
      border-radius: 25px;
      opacity: 0.9;
      font-size: 20px;
      color: #ffffff;
    }
      .lrcLink{
  margin-top: 10px;
  margin-left: 185px;
}
  }

  &.mobile {
    .count {
      .countrgh {
        .counticon {
          margin: 6px;
        }
      }
    }

    .footer {
      .sub {
        width: 70%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 20px;
}

span {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #270645;
}

.title {
  font-weight: bold;;
  font-size: 15px !important;
  font-weight: bold !important;
  font-stretch: normal;
  color: #270645;
}
.Contacts{
  cursor: pointer;
  font-family: Helvetica;
	text-decoration: underline;
	font-size: 13px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 15px;
	letter-spacing: 0px;
	color: #7800f4;
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 130px;
  margin-top: 5px;
  border-radius: 50%;

  // box-shadow: 0 0 10px #fff;
  // border-radius: 50%;
}

  ::v-deep .v-messages__message {
    color: red;
  }
</style>
