<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        width="31px"
        height="31px"
        @click="closeDialog"
        :src="this.$vuetify.theme.dark ? darkImg :brighImg"
        alt=""
      />
      <div class="top" :class="{darkTop:this.$vuetify.theme.dark }">
        <h3 :class="{darkFont:this.$vuetify.theme.dark }">{{ $t("messageMessage") }}</h3>
        <div class="auth">
          <span class="width-18"  :class="{darkFont:this.$vuetify.theme.dark }">{{ currfriend.fansName }}</span>
          <span class="val"  :class="{darkFont:this.$vuetify.theme.dark }">{{ tineValue }}</span>
        </div>
      </div>
      <div id="msgbox" ref="msgbox">
        <ul v-scroll:#msgbox="onScroll">
          <li v-for="i in msgList" :key="i.id">
            <img class="fansPhoto" :src="i.fansPhoto" alt />
            <div class="cont">
              <pre class="msg" :class="{darkFont:isDark }">{{ i.message }}</pre>
              <span class="time" :class="{darkFont:isDark}">{{ i.date }}</span>
            </div>
          </li>
        </ul>
      </div>

      <div class="ti-tx">
        <!--        <span class="title">{{$t('popupAccountAppealDescription')}}</span>-->
      </div>
      <div class="footer" ref="footer">
        <v-textarea
          class="txt"
          label
          placeholder
          v-model="msgtxt"
          outlined
          auto-grow
          rows="1"
           :class="{whiteFont:this.$vuetify.theme.dark }"
        ></v-textarea>
        <img
          class="sub"
          src="../../assets/icons/btn_send.png"
          @click="subCommit"
        />
        <!-- <span class="sub" submit @click="subCommit"></span> -->
      </div>
    </v-card>

    <PromptBox ref="promptBox"></PromptBox>
  </div>
</template>

<script>
import PromptBox from "@/components/PromptBox.vue";

import api from "@/api";
import { getFileSrc } from "@/utils/file";
import img from "@/assets/default.png";
const WORK_KEY = "WORK";

//留言弹层
export default {
  components: {
    PromptBox,
  },
  data: () => ({
    open: true,
    tineValue: "",
    senderPhoto: "",
    msgtxt: "",
    img,

    loading: false,
    msgList: [],
    totalPage: 0,
    pageNumber: 1,
    pageSize: 20,
    pointer: 0,
    hasMessage:false,
    brighImg:require('@/assets/btn_close.png'),
    darkImg:require('@/assets/darkimage/btn_close1.png'),
    isDark:false

  }),
  props: {
    currfriend: {
      type: Object,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    msgtxt() {
      this.txtKeyup();
    },
  },
  mounted() {
         // 判断是否为黑暗模式
        let isDarkMode =  localStorage.getItem('isDarkMode');
        if(isDarkMode){
             if(isDarkMode == 'true'){
              this.isDark = true
           }else{
             this.isDark = false 
           } 
        }else{
            this.isDark = false
        }
           window.eventBus.$on("DarkModeChange", this.DarkModeChange);
    this.init();
  },
  methods: {
    goto() {
      const { scrollHeight } = this.$refs.msgbox;

      return this.$vuetify.goTo(scrollHeight - this.pointer, {
        container: this.$refs.msgbox,
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      });
    },
    onScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      this.pointer = scrollHeight - clientHeight - scrollTop;
      if (scrollTop < 80 && !this.loading && this.pageNumber <= this.totalPage) {
        this.getList();
      }
    },
    async getList() {

      if(this.hasMessage){
        return
      }
      this.loading = true;
      this.hasMessage = true
      let params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
         chainType: "IRIS_IRISHUB",
        friendAddress: this.currfriend.fansAddress,
        userAddress:this.$store.state.did
      };
      let res = await api.home.messageRecord(params);
      this.hasMessage = false
      this.totalPage = res.data.totalPage;
      this.pageNumber++;
      const list = res.data.list.map((v) => {
        const item = {
          id: v.id,
          message: v.content,
          fansPhoto: img,
          date: this.timestampToTime(v.sendTime),
        };
        if (v.senderAddress == this.$store.state.did) {
			if(v.senderPhoto==null){
		v.senderPhoto="QmfNYoCZ4M9oDHZhtfLq63PK7ur1kJTZpRis9t6VEjqWMV"  //
			}
			item.fansPhoto=getFileSrc(WORK_KEY, v.senderPhoto);
						// getFileSrc(WORK_KEY, v.senderPhoto).then((res) => {
						//   if (res) {
						//     item.fansPhoto = res;
						//   }
						// });
 
        } else {
			if(v.receiverPhoto!=null){
				
          item.fansPhoto =  getFileSrc(WORK_KEY, v.receiverPhoto);
		  			// getFileSrc(WORK_KEY, v.receiverPhoto).then((res) => {
		  			//   if (res) {
		  			//     item.fansPhoto = res;
		  			//   }
		  			// });
			}

        }
        return item;
      });
      this.msgList = list.concat(this.msgList);
      this.$nextTick(async () => {
        await this.goto();
        this.loading = false;
      });
    },
     DarkModeChange(value){
        this.isDark = value
     },

    async init(value) {
      // document.getElementsByTagName("textarea")[0].addEventListener("change", this.txtKeyup);
      if (value) {
        this.currfriend = value;
      }
      if (
        this.currfriend.fansAddress &&
        this.currfriend.fansAddress.length > 12
      ) {
        this.tineValue =
          this.currfriend.fansAddress.substr(0, 6) +
          "..." +
          this.currfriend.fansAddress.substr(-6);
      }
      this.pageNumber = 1;
      this.msgList = [];
      this.getList();

      //   setTimeout(() => {
      //     document.getElementsByClassName("msgbox")[0].scrollTop =
      //       document.getElementsByTagName("ul")[0].offsetHeight + 1000;
      //   }, 300);

        let params = {
                   address: this.$store.state.did,
                   chainType: this.$store.state.chainType
     };
      let info = await api.home.loopringUserInfo(params);
	  if(!info.data.profilePhoto){
		  info.data.profilePhoto= "QmfNYoCZ4M9oDHZhtfLq63PK7ur1kJTZpRis9t6VEjqWMV"
	  }
      let photo = await getFileSrc(WORK_KEY, info.data.profilePhoto);
   
      this.senderPhoto = photo;
    },
    //提交转送数据
    async subCommit() {
      if (!this.msgtxt) {
        this.$refs.promptBox.show("Information cannot be empty");
        return;
      }
      let params = {
        chainType: "IRIS_IRISHUB",
        sender: this.$store.state.did,
        receiver: this.currfriend.fansAddress,
        content: this.msgtxt,
      };

      api.home.message(params);

      this.msgList.push({
        // id: v.id,
        message: this.msgtxt,
        fansPhoto: this.senderPhoto,
        date: this.timestampToTime(new Date().getTime()),
      });
      this.pointer = 0;
      this.goto();


      this.msgtxt = "";
      // this.open = false
      // this.$emit('getOpen', this.newOpen);
    },
    //关闭弹框，发送open值
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
      // console.log(this.$store.state.userAddress);
    },
    //进入小狐狸
    foxBtn() {
      alert(111);
    },
    timestampToTime(time) {
      let date = new Date(time);
      let Y = date.getFullYear() + "-";
      let M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      let D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      let h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      let m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      let s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let strDate = Y + M + D + h + m + s;
      return strDate;
    },
    txtKeyup() {
      // console.log(e.target.offsetTop, this.$refs.footer.offsetTop);
      let h = this.$refs.footer.offsetTop - 75;
      this.$refs.msgbox.style.height = h + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.width-18 {
  display: inline-block;
  width: 100px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.card {
  width: 400px;
  height: 600px;

  // min-height: 600px;
  .clo {
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    right: 15px;
    top: 10px;
    border-radius: 50%;
    cursor: pointer;
  }

  .top {
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eee;
    &.darkTop{
       border-bottom: 1px solid #272727 !important;
    }

    h3 {
      line-height: 50px;
      font-size: 20px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      color: #270645;
    }

    .auth {
      position: absolute;
      // top: 34px;
      right: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      height: 50px;

      span {
        width: 54px;
        height: 13px;
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #270645;
        margin-right: 15px;
        text-align: right;
      }

      .val {
        width: 100px;
        height: 10px;
        font-family: "\5FAE\8F6F\96C5\9ED1";
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        line-height: 8px;
        color: #270645;
        margin-top: 7px;
        text-align: right;
      }
    }
  }

  #msgbox {
    margin: 10px 25px;
    height: 470px;
    overflow: hidden;
    overflow-y: auto;

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        top: 0;
        left: 0;
        margin-top: 12px;
        // min-height: 60px;

        img {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-top: 5px;
        }

        .cont {
          clear: both;
          display: inline-block;
          // width: 300px;
          min-height: 45px;
          // margin: 0 0 19px 66px;
          margin-left: 40px;
          display: flex;
          flex-direction: column;

          .msg {
            width: 100%;
            // min-height: 17px;
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 14px;
            // font-weight: normal;
            // font-stretch: normal;
            // letter-spacing: 0px;
            color: #270645;
            white-space: pre-wrap;
            white-space: -moz-pre-wrap;
            white-space: -pre-wrap;
            white-space: -o-pre-wrap;
            word-wrap: break-word;
          }

          .time {
            // margin-left: 11px;
            // width: 84px;
            // height: 9px;
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .ti-tx {
    margin: 25px 28px;

    .title {
      width: 85px;
      height: 17px;
      font-family: "\5FAE\8F6F\96C5\9ED1";
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .txt {
      width: 350px;
      height: 40px;
      max-height: 100px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#f8f6fd, #f8f6fd);
      background-blend-mode: normal, normal;

      &::v-deep textarea {
        max-height: 100px;
      }
    }
  }

  .footer {
    display: flex;
    align-items: flex-end;
    position: absolute;
    left: 25px;
    right: 25px;
    bottom: -15px;

    .txt {
      margin-right: 10px;
      &::v-deep .v-input__slot {
        min-height: 40px !important;
        display: flex;
        align-items: center;
      }
      &::v-deep textarea {
        max-height: 100px;
        color: #270645;
        font-size: 14px;
        line-height: 22px;
        padding-top: 3px;
      }
     
    }
    .whiteFont{
       margin-right: 10px;
      &::v-deep .v-input__slot {
        min-height: 40px !important;
        display: flex;
        align-items: center;
      }
      &::v-deep textarea {
        max-height: 100px;
        color: #ffffff;
        font-size: 14px;
        line-height: 22px;
        padding-top: 3px;
      }
    }

    .sub {
      width: 36px;
      height: 36px;
      margin-bottom: 33px;
      cursor: pointer;
    }

    &::v-deep textarea {
      margin-top: 5px !important;
    }
  }

  &.mobile {
    .footer {
      .sub {
        width: 36px;
        height: 36px;
      }
    }
  }
}

.fansPhoto {
  border-radius: 50%;
}

.v-card {
  box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 9%) !important;
}

.mobile.card {
  width: 350px !important;
  height: 600px;
}
</style>
