<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
        alt=""
      />
      <div class="mainContain d-flex flex-column align-center justify-center">
        <img
        class="notice"
        src="@/assets/icons/rwa/vc_no.png"
        alt=""
      />
          <div class="name mt-5" :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_role_limit')}}</div>
      

      </div>
    </v-card>

    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
export default {
  data: function () {
    return {
      newOpen: true,
      closeIcon: require("@/assets/btn_close.png"),
      darkcloseIcon: require("@/assets/darkimage/btn_close1.png"),
  
    };
  },
  props: {},
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {},
  methods: {
    async subCommit() {
      try {
        console.log("subCommit");
      } catch (error) {
        this.$toast("error", this.$t("requireFailed"));
      }
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
   
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 225px;
  width: 500px;
  padding:0 90px;

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .mainContain {
    margin-top: 46px;
    .title-23{
      font-family: Helvetica;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 3px;
    letter-spacing: 0px;
    color: #270645;
    }
    .name{
      font-family: Helvetica;
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #270645;
  text-align: center;

    }
    .btn{
      margin-top:35px;
        width: 221px;
        height: 51px;
        background-color: #7800f4;
        border-radius: 25px;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
    }
    .notice{
      width:52px;
      height:52px;
    }
   
  }

  &.mobile {
    padding: 0 40px;
  }
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;
}
</style>
