<template>
   <div class="contain">
      <v-card id="card" :class="{ card: true, mobile: !isIpad }">
         <img
             class="clo"
             width="31px"
             height="31px"
             @click="closeDialog"
             :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
             alt=""
         />
         <!-- <img class="code" src="@/assets/default.png" alt="" /> -->
         <vue-qr :text="addressUrl" :size="260" class="code"></vue-qr>
         <v-dialog v-model="show">
            <vue-qr :text="addressUrl" :size="260" class="codecopy"></vue-qr>
         </v-dialog>

         <div class="reminder">
            <!-- <img class="codeicon" :src=" $vuetify.theme.dark ? darkcodeIcon : codeIcon" alt=""/> -->
            <div class="QR" v-if="isShowIcon" :class="{darkFont:$vuetify.theme.dark }" v-html="$t('popupPayScanQR')"></div>
            <div class="QR" v-else :class="{darkFont:$vuetify.theme.dark }" v-html="$t('popupPayOtherScanQR')"></div>
            
         </div>
       <div class="downloadApp"  :class="{darkFont:$vuetify.theme.dark }">{{$t('popupPayDownload')}}</div>

         <div class="download d-flex flex-lg-row flex-column algin-center justify-center">
            <!-- <img class="downicon" :src="  $vuetify.theme.dark ? darkdownIcon : downIcon" alt=""/>
            <a class="downloadText" :class="{darkFont:$vuetify.theme.dark }"  @click="toDownLoad">{{$t('popupPayDownload')}}</a> -->
            <div class="box mr-lg-4 mr-0 d-flex  flex-row" :class="{darkColor:$vuetify.theme.dark }" @click="toDownLoad" v-if="isShowIcon">
               <img class="upticklogo mr-3" src="@/assets/icons/uptick_download.png" alt=""/>
               <div >
                  <div class="titlename" :class="{darkFont:$vuetify.theme.dark }">Uptick</div>
                  <div class="sourceTitle" :class="{darkFont:$vuetify.theme.dark }">NFT Marketplace</div>
               </div>
            </div>
            <div class="box d-flex flex-row" :class="{darkColor:$vuetify.theme.dark }" @click="toOpenUpward">
               <img class="upticklogo mr-3" src="@/assets/icons/upward_download.png" alt=""/>
               <div>
                  <div class="titlename" :class="{darkFont:$vuetify.theme.dark }">Upward</div>
                  <div  class="sourceTitle" :class="{darkFont:$vuetify.theme.dark }"> Wallet</div>
               </div>
               </div>
            </div>
          
     
      </v-card>
   </div>
</template>
<script>
  import vueQr from "vue-qr";

  export default {
    data: () => ({
      open: true,
      show: false,
      isIpad:false,
      isShowIcon:true,
      codeIcon:require('@/assets/icons/icon_scan.png'),
      darkcodeIcon:require('@/assets/darkimage/icon_scan.png'),
      downIcon:require('@/assets/icons/icon_download.png'),
      darkdownIcon:require('@/assets/darkimage/icon_download.png'),
      closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
    }),
    components: {
      vueQr
    },
    props: {
      Nft_sku: {
        type: Object
      },
     
    },
    	beforeDestroy () {
      if (typeof window === 'undefined') return

      window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted(){
this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
         // 初始化
    let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
    if(currentChain== 'UPTICK'){
      this.isShowIcon = true
    }else if(currentChain == 'BSC' || currentChain == 'POLYGON' || currentChain == 'ARBITRUM'){
      this.isShowIcon = false
    }
    },
    computed: {
      addressUrl() {
        console.log(this.Nft_sku);
        let chainId = process.env.VUE_APP_ADD_NETWORK_CHAIN_ID
        let chainIdInt =  parseInt(chainId, 16)
      console.log( '-------',chainIdInt);
      if(this.Nft_sku.nftType=='ERC1948'){
         return `{"source":"web","target":"buy1948","nftAddress":"${this.Nft_sku.nftAddress}","nftId":"${this.Nft_sku.nftId}","owner":"${this.Nft_sku.owner}","chainId":"${chainIdInt}"}`;
    
      }else{
         return `{"source":"web","target":"buy","nftAddress":"${this.Nft_sku.nftAddress}","nftId":"${this.Nft_sku.nftId}","owner":"${this.Nft_sku.owner}","chainId":"${chainIdInt}"}`;
      }
        
      },
      isMobile: function() {
        return this.$vuetify.breakpoint.mobile;
      }
    },
    methods: {
      gethost(){
      let url = window.location.href 
 
     var urlstr = url.split("/"); 
    var urls = '';
    if (urlstr[2]) {
        urls = urlstr[0]+'//'+urlstr[2];
    }
      return urls
    },
       	onResize () {
        this.isIpad = 900<window.innerWidth
      },
       toDownLoad(){

           let host = this.gethost()
           window.open(host+'/downloads','_blank')
       },
       toOpenUpward(){
         window.open('https://upward.uptick.network','_blank')
      

       },
      subCommit() {
      },
      closeDialog() {
        this.open = false;
        this.$emit("getOpen", this.open);
      },
      showBtn() {
        let that = this;
       
        let address = that.address;
        let price = that.price;
        const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isiOS) {
          return `uptickevm://nftAddress=${this.Nft_sku.nftAddress}&nftId=${this.Nft_sku.nftId}&owner=${this.Nft_sku.owner}&auctionNo=${this.Nft_sku.auctionNo}`; 
        } else {
          return `uptickonupticknetwork://?nftAddress=${this.Nft_sku.nftAddress}&nftId=${this.Nft_sku.nftId}&owner=${this.Nft_sku.owner}&auctionNo=${this.Nft_sku.auctionNo}`;
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
   // .contain {
   .card {

      display: flex;
      flex-direction: column;
      // max-height: 490px;
      // width: 600px;
      
      .clo {
         display: block;
         position: absolute;
         right: 10px;
         top: 10px;
         border-radius: 50%;
         cursor: pointer;
      }

      .code {
         margin: 56px 200px 0 200px;
         width: 200px;
         height: 200px;
      }

      .reminder {
         margin-top: 15px;
      

         .QR {
            margin-left: 10px;
            text-align: center;
            width: 100%;
            height: 17px;
            font-weight: bold;;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
       
         }

         .codeicon {
            margin-left: 147px;
            margin-right: 0;
            vertical-align: middle;
            margin-bottom: 8px;
            display: inline-block;
            width: 20px;
            height: 20px;
         }
      }

      

      .downlink {
         display: none;
      }
      .downloadApp{
         width: 100%;
         font-family: Helvetica;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #270645;
   text-align: center;
   margin-top: 50px;
      }

      .download {
         margin-top: 15px;
         margin-bottom: 21px;
         width: 100%;
         .darkColor{
            background-color: rgba(243, 229, 249,0.2)!important;
         }

        .box{
         width: 201px;
         height: 81px;
         background-color: rgba(243, 229, 249);
         border-radius: 5px;
         padding:15px 0 15px 20px;
         cursor: pointer;
         .upticklogo {
    
         width: 50px;
         height: 50px;
         
      }
      .titlename{
         font-family: Helvetica;
         font-size: 12px;
         font-weight: bold;
         font-stretch: normal;
         line-height: 30px;
         letter-spacing: 0px;
         color: #270645;
      }
      .sourceTitle{
         font-family: Helvetica;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 13px;
	letter-spacing: 0px;
	color: #766983;
      }
        }
      }

      &.mobile {
         overflow-x: hidden;
         width: 300px !important;
         height: auto;
         padding: 0 10px;

         .code {
            display: none !important;
            margin: 30px auto;
         }

         .codecopy {
            width: 100px;
            height: 100px;
            margin: 60px auto;
         }

         .reminder {
            display: none;
         }

         .upticklogo {
            // margin: 50px auto;
         }

         .downlink {
            display: block;
            font-weight: bold;;
            text-decoration: underline;
            font-size: 20px;
            font-weight: normal;
            text-decoration: underline;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
            margin: 0 auto 35px;
         }

         .download {
            margin: 0 auto 20px;
       align-items: center;
            .box{
               margin-top: 20px;
            }

            .downicon {
               margin: 0;
            }

            .downloadText {
               margin-bottom: 45px;
            }

            .buttomtext {
               display: none;
            }
         }
      }
   }

   // }
</style>




