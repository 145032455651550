<template>
    <div id="container">
      <v-card :class="{ card: true, mobile: isMobile }">
       
        <img
          class="clo"
          @click="closeDialog"
          :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
          alt=""
        />
        <div class="title mb-3"  :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_fill_description') }}</div>
        <div class="title-15"  :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_fill_btn_notice') }}</div>
        <div>
            <div class="d-flex flex-row justify-space-between align-center">
                <div
            class="name mt-6 mb-3"
            :class="{ darkFont: this.$vuetify.theme.dark }"
          >
            {{ $t("rwa_name") }}
          </div>
         
            </div>
         
          <div class="box">
            <v-text-field
              ref="nameValue"
              v-model="nameValue"
               counter="80"
              label=""
             maxLength="80"
              outlined
            
            >
            </v-text-field>
          </div>
        </div>

        <div>
            <div class="d-flex flex-row justify-space-between align-center ">
                <div
            class="name mb-3"
            :class="{ darkFont: this.$vuetify.theme.dark }"
          >
            {{ $t("rwa_description") }}
          </div>
          <!-- <div class="limitContent">{{desValue.length}}/800</div> -->
            </div> 

            <div class="box">
            <v-textarea
              ref="desValue"

              outlined
              counter="800"
              name="input-7-4"
              label=""
              value=""
              v-model="desValue"
               maxLength="800"

            ></v-textarea>
          </div>
       
        </div>
        <div class="title-15-normal mb-3"  :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_images') }}</div>

        <div class="upload">
            <div style="display: flex;flex-direction: row;flex-wrap: wrap;">
               <div class="uploadImage" v-for="(item,index) in uploadfile" :key="index">
                  <img class="img" :src="item"/>
                  <img class="icon" :src="$vuetify.theme.dark?darkcloseIcon1:closeIcon1" @click="imgRemove(index)"/>
               </div>

               <div class="uploadfile" :class="{somberBackground:this.$vuetify.theme.dark,imgDrap:isDragImg }" v-if="uploadfile.length<6"   @drop="enentDrop"
              @dragleave="dragleave"
              @dragenter="dragleave"
              @dragover="dragleave"
              @dragend="endContain">
                  <FIleInput class="FIleInput" @change="change" :key="fileInputKey" accept=".jpg,.jpeg,.png,.gif" v-if="!isDrop">
                     <v-icon >mdi-plus</v-icon>
                
                  </FIleInput>
                  <div class="progress_area" v-if="isDrop">
              <span :style="{ width: progressValue + '%' }"></span>
            </div>
               </div>
            </div>
            <span class="file-limit">{{$t("popupNftAppealLimit")}}</span>
         </div>
         <button class="btn" :class="{'sub-dis': isPay}" @click="submit">{{$t('myInfoFormSave')}}</button>
         <div></div>
        <!-- <div class="mainContain d-flex flex-column align-center justify-center">
          <img
          class="notice"
          src="@/assets/icons/rwa/vc_no.png"
          alt=""
        />
            <div class="name mt-5" :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_role_limit')}}</div>
        
  
        </div> -->
      </v-card>
  
      <uComponents ref="ucom"></uComponents>
    </div>
  </template>
  <script>
  import api from "@/api";
  import FIleInput from "@/components/FIleInput.vue";
  export default {
    data: function () {
      return {
        isPay: false,
        isDrop: false,
        progressValue: 0,
        isDragImg:false,
        uploadfile: [],
        newOpen: true,
        closeIcon: require("@/assets/btn_close.png"),
        darkcloseIcon: require("@/assets/darkimage/btn_close1.png"),
        closeIcon1: require("@/assets/icons/icon_delete2.png"),
        darkcloseIcon1: require("@/assets/icons/icon_d.png"),
        nameValue:'',
        desValue:'',
        imgUrl: [],
        fileInputKey: 0,
      };
    },
    components: { FIleInput },
    props: {
		nftAddress: {
		  type: String,
		},
		serialNumber: {
		  type: String,
		},
	},
    computed: {
      isMobile: function () {
        return this.$vuetify.breakpoint.mobile;
      },
    },
   async mounted() {
    let params = {
      nftAddress: this.nftAddress,
      serialNumber:this.serialNumber,
    }
    let productInfo = await api.home.getExtensionInfo(params)

    if(productInfo?.data){
      console.log('wxl ----- productInfo',productInfo.data.imgUrls);
      this.nameValue = productInfo.data.name
      this.desValue =  productInfo.data.description
      JSON.parse(productInfo.data.imgUrls).forEach(element => {
         let url = this.getImageIpfs(element);
         this.uploadfile.push(url);
         this.imgUrl.push(element);
        
        
      });


   
      

    }
    


      
    },
    methods: {
      onUploadProgress(e) {
      this.progressValue = (e.loaded / e.total) * 100;
    },
		async submit(){
      this.isPay = true
	let param = {
	  nftAddress: this.nftAddress,
	   name: encodeURIComponent(this.nameValue),
	  description: encodeURIComponent(this.desValue),
	 serialNumber:this.serialNumber,
	  imgUrls:this.imgUrl.join(',')
	};
	
	let deploy_res = await api.home.updateExtension(param);
  console.log('wxl ----- deploy_res',deploy_res);
  if (deploy_res.success == true) {
    this.isPay = false
        this.$toast("success", this.$t("success"))
        this.$emit("getOpen", false);
        window.eventBus.$emit('collectionPay');
        localStorage.setItem("collection_card", 7);
        this.$router.push({ name: "PersonalPage" });
       
      
      } else {
        this.isPay = false
        this.$toast("error", this.$t(deploy_res.msg));
      }


  
	
		},
		
        async change(file) {
           this.isDrop = true;
         let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF".indexOf(ft.toUpperCase()) == -1) {
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
         this.isDrop = false;
        return;
      }
       if (file.size > 20971520) {
           this.isDrop = false;
          this.$toast("error", this.$t("image_not_support"));
          return;
        }

         let path = await api.image.uploadImage(file,this.onUploadProgress);
        let  imageUrl = path.data.data;
		    this.fileInputKey++;
		    let url = this.getImageIpfs(imageUrl);
         this.uploadfile.push(url);
         this.imgUrl.push(imageUrl);
         this.isDrop = false;
        
   

      },

      imgRemove(idx) {
        this.uploadfile.splice(idx, 1);
        this.imgUrl.splice(idx, 1);
      },
      enentDrop(e) {
               e.stopPropagation();
      e.preventDefault(); //必填字段
           console.log('111111111');
      this.isDragImg = false
  
      let file = e.dataTransfer.files[0];
      this.change(file);
    },
    enentDropImg(e) {
       console.log('222222');
      e.stopPropagation();
      e.preventDefault(); //必填字段
      let file = e.dataTransfer.files[0];
      this.imgChange(file);
    },
    dragleave(e) {
       console.log('33333333');
         this.isDragImg = true
      e.stopPropagation();
      e.preventDefault();
    },

    dragContain(e) {
       console.log("44444");
      this.isDragImg = false;
    },
    leaveContain(e) {
       console.log("55555");
      this.isDragImg = true;
      e.stopPropagation();
      e.preventDefault();
    },
    endContain(e) {
       console.log("666");
      this.isDragImg = false;
      e.stopPropagation();
      e.preventDefault();
    },
      async subCommit() {
        try {
          console.log("subCommit");
        } catch (error) {
          this.$toast("error", this.$t("requireFailed"));
        }
      },
      closeDialog() {
        debugger
        this.open = false;
        this.$emit("getOpen", this.open);
        localStorage.setItem("collection_card", 7);
        this.$router.push({ name: "PersonalPage" });
      },
     
    },
  };
  </script>
  <style lang="scss" scoped>
  .card {
    display: flex;
    flex-direction: column;
    min-height: 650px;
    width: 610px;
    padding:0 50px;
    .progress_area {
  width: 105px;
  height: 10px;
  background-color: #bbb;
  border-radius: 30px;
  overflow: hidden;
  margin-top: 110px;


  span {
    display: block;
    background-color: #766983;
    height: 100%;
    width: 0%;

  }
}
    .clo {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 30px;
      height: 31px;
      border-radius: 50%;
      margin-right: 0;
      cursor: pointer;
    }
    .btn{
        width: 500px;
	height: 51px;
	background-color: #7800f4;
	border-radius: 25px;
    font-family: Helvetica;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #ffffff;
    margin-top: 25px;
    margin-bottom: 40px;
    }
   
.sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
   margin-left: 10px;
  margin-top: 5px;
}
    .limitContent{
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #766983;
    }
    .name{
        font-family: Helvetica-Bold;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #270645;
    }
    // .box {
    //         position: relative;
    //            &::v-deep  .v-input__slot {
    //             height:46px!important;
    //             min-height: 46px;
                
               
    //         }
    //           &::v-deep .v-text-field input{
    //             max-width: 100%;
    //             height:46px!important;
                
    //           }
           
    //       }
    .title{
        font-family: Helvetica;
	font-size: 23px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 30px;
	letter-spacing: 0px;
	color: #270645;
    margin-top: 35px;
    }
    .title-15{
        font-family: Helvetica;
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #766983;
    }
    .title-15-normal{
        font-family: Helvetica;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	line-height: 20px;
	letter-spacing: 0px;
	color: #270645;
    }
    .upload {
          .imgDrap{
            border: solid 2px #7800f4 !important;
        }
      

        .uploadImage {
            &::v-deep .v-text-field{
                padding-top: 0px;
    margin-top: 0px;
            }
            &:nth-child(3n) {
          margin-right: 0;
        }
            margin-bottom: 24px;
            width: 140px;
            height: 140px;
       
            background-color: #ffffff;
            border-radius: 10px;
       

            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 25px;
            cursor: pointer;

            .FIleInput {
               width: 100%;
               height: 100%;

               .v-icon {
                  width: 140px;
                  height: 140px;
                  padding: 25px;
               }

            }
            .uploadbox {
         margin-top: -90px;
        width: 101px;
        height: 101px;
    
      }

            .icon {
               width: 20px;
               height: 20px;
               border-radius: 50%;
               position: absolute;
               top: -10px;
               left: 125px;
               background-color: #fff;
               z-index: 99;
               cursor: pointer;
            }

            .img {
               width: 140px;
               height: 140px;
               border-radius: 10px;
               margin: 0;
               object-fit: cover;
            }
         }
         .uploadfile {
            &::v-deep .v-text-field{
                padding-top: 0px;
    margin-top: 0px;
            }
            margin-bottom: 14px;
            width: 150px;
            height: 150px;
       
            background-color: #ffffff;
            border-radius: 10px;
            border: solid 2px #e3e3e3;

            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin-right: 30px;
            cursor: pointer;

            .FIleInput {
               width: 100%;
               height: 100%;

               .v-icon {
                  width: 150px;
                  height: 150px;
                  padding: 25px;
               }

            }
            .uploadbox {
         margin-top: -90px;
        width: 101px;
        height: 101px;
    
      }

            .icon {
               width: 20px;
               height: 20px;
               border-radius: 50%;
               position: absolute;
               top: -10px;
               left: 135px;
               background-color: #fff;
               z-index: 99;
               cursor: pointer;
            }

            .img {
               width: 150px;
               height: 150px;
               margin: 0;
            }
         }
         

         .file-limit {
            // width: 137px;
            height: 13px;
            font-family: Helvetica;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #766983;
         }
      }
  
    &.mobile {
      .btn{
        width: 100%;
      }
    }
  }
  
  .sub-dis {
    position: relative;
    pointer-events: none;
    background-image: linear-gradient(#766983, #766983),
      linear-gradient(#270645, #270645) !important;
    background-blend-mode: normal, normal;
    border-radius: 25px;
    opacity: 0.9;
  }
  .sub-dis::after {
    content: "";
    background-image: url(../../assets/loading.gif);
    background-size: 100%;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    margin-left: 110px;
  }
  </style>
  