<template>
  <v-card class=" elevation-0" @mouseenter="mousedCard()" 	@mouseleave="leaveCard()" :class="{'rd':type !== 'rwa' ,'normalMode':!this.$vuetify.theme.dark ,'bord':isCreateCheck,smallsmallCard:smallsmallCard ,bigCard:bigCard ,filterBigCard:filterBigCard ,showCaseCard:showCaseCard}" >
    <Work :hash="hash" :attribute="attribute" :isCreateCheck="isCreateCheck" :fileUrl="fileUrl" :owerfrom="owerfrom"  :value="value" @imgClick="workClick" :cardType="type" />
    <AuctionCardContent v-if="type === 'auction'" :value="value" />
    <SellCardContent v-else-if="type === 'sell'" :value="value" :isMinPrice="isMinPrice" />
	 <MarketplaceCardContentNew v-else-if="type === 'other'" :value="value" :isMinPrice="isMinPrice"  :owerfrom="owerfrom" :selectType='selectType' />
    <MyCardContent v-else-if="type === 'my'" :value="value"  :isShowCard='isShowCard'/>
    <CollectCardContent v-else-if="type === 'collect'" :value="value" />
    <PageCardContent v-else-if="type === 'page'" :value="value"  />
    <!-- <OtherWorkCard v-else-if="type === 'other'" :value="value" :owerfrom="owerfrom" :selectType='selectType' /> -->
    <!-- 集合主页下展示 -->
     <OtherPageWorkCard v-else-if="type === 'otherPage'" :value="value" :owerfrom="owerfrom" :selectType='selectType' />
    <MycollectCard v-else-if="type === 'mycollect'" :value="value" />
     <ClearModeCard v-else-if="type === 'clearmode'" :value="value" />
     <RwaCard v-else-if="type === 'rwa'" :value="value" />
     <MyRwaCard v-else-if="type === 'myRwas'" :value="value" :isShowCard='isShowCard' />
     
    <ShowCaseCard v-else-if="type === 'showCase'" :value="value"  :owerfrom="owerfrom"/>
    
    <slot></slot>
  </v-card>
</template>

<script>
import Work from "@/components/work/index.vue";
import AuctionCardContent from "./AuctionCardContent.vue";
import SellCardContent from "./SellCardContent.vue";
import MarketplaceCardContentNew from "./MarketplaceCardContentNew.vue";
import MyCardContent from "./MyCardContent.vue";
import CollectCardContent from "./CollectCardContent.vue";
import PageCardContent from "./PageCardContent.vue";
import OtherWorkCard from "./OtherWorkCard.vue"
import OtherPageWorkCard from "./OtherPageWorkCard.vue"
import MycollectCard from "./MycollectCard.vue"
import ClearModeCard from "./ClearModeCard.vue";
import ShowCaseCard from "./showCaseCard.vue";
import RwaCard from "./RwaCardContent.vue"; 
import MyRwaCard from "./MyRwaCardContent.vue"; 




export default {
  name: "Card",
  components: { OtherWorkCard,OtherPageWorkCard, Work, AuctionCardContent, SellCardContent, MyCardContent, CollectCardContent, PageCardContent, MycollectCard,ClearModeCard,ShowCaseCard,MarketplaceCardContentNew,RwaCard,MyRwaCard },
  props: {
    isCreateCheck:{
      type:Boolean
    },
    value: {
      type: Object,
    },
    type: {
      type: String, // auction / sell / my / collect
    },
    owerfrom: {
      type: String,
    },
    favorite: {
      type: String
    },
    isMinPrice: {
      type: String
    },
    pagetype: {
      type: String
    },
    smallCard:{
      type:Boolean
    },
    smallsmallCard:{
       type:Boolean
    },
    bigCard:{
       type:Boolean
    },
    filterBigCard:{
       type:Boolean
    },
    selectType:{
      type:Number
    },
    showCaseCard:{
       type:String
    }
  },
  data: () => ({
    loading: false,
    selection: 1,
    isShowCard:false,
  }),
  mounted() {
      console.log('wxl  my card type 9999999',this.type);
      
    
    },
  computed: {
    hash: function () {
      return this.value && this.value.imgUrl;
    },
    attribute: function () {
      return this.value && this.value.attribute;
    },
    fileUrl: function () {
      return this.value && this.value.fileUrl;
    },
  },
  methods: {
    mousedCard(){
      this.isShowCard = true
    },
    leaveCard(){
        this.isShowCard = false
    },
    reserve() {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
    workClick() {
      debugger
      console.log("this.owerfrom 999",this.owerfrom,this.type);
      if(this.showCaseCard == 'editshowCaseCard'){
        return;
      }
   
      if(this.value.imgUrl == 'QmQsyHdciAVAzC1edcMx4eQjASeWfWg8piHtbbh9n5tyWN'){
       return;
     }
      if (this.owerfrom == "personalPage") {
         let query = { };
         query.nftAddress = this.value.nftAddress;
          query.nftId = this.value.nftId;
          query.owner = this.value.owner;
          if(this.type == 'myRwas' || this.type == 'rwa'){
            query.serialNumber = this.value.serialNumber;

            this.$router.push({ name: "RwaCollectDetail", query });
          } else if(this.type == 'my' && this.value.nftType== 'ERC1948'){
        
            if(this.value.marketType == 2){
              this.$router.push({ name: "RwaAuctionDetail", query });
            }else{
              this.$router.push({ name: "ProductDetail", query });
            }
            
          }
          else{
            if(this.value.marketType== '2'){
              
         
         this.$router.push({ name: "Detail", query });
           }
           else{
              this.$router.push({ name: "Saledetail", query });
           }
          }
        

       
      } else if (this.owerfrom == "auction") {
        if (this.value.auctionNo == null) {
          this.$router.push({ name: "Saledetail", query: { id: this.value.id } });
        } else {
          this.$router.push({ name: "Detail", query: { auctionNo: this.value.auctionNo } });
        }

      } else if (this.owerfrom == "my") {

 let query = { };
         query.nftAddress = this.value.nftAddress;
          query.nftId = this.value.nftId;
          query.owner = this.value.owner;
          if(this.type == 'myRwas' || this.type == 'rwa'){
            query.serialNumber = this.value.serialNumber;

            this.$router.push({ name: "RwaCollectDetail", query });
          }
        else if(this.value.marketType== '2'){
          if(this.value.nftType == 'ERC1948') {
            this.$router.push({ name: "rwaauctiondetail", query });
          }else{
            this.$router.push({ name: "Detail", query });
          }
          
         
   
        }else{
         if(this.value.nftType == 'ERC1948') {
          this.$router.push({ name: "ProductDetail", query });
          }else{
            this.$router.push({ name: "Saledetail", query });
          }
         
        }
    
      }else if(this.owerfrom == "create"){
        console.log("create")
      }
      else if(this.owerfrom=="resale"){
         let query = { };
         query.nftAddress = this.value.nftAddress;
          query.nftId = this.value.nftId;
          query.owner = this.value.owner;

          
          if(this.value.nftType == 'ERC1948' ){
            console.log('0000');
            
            // query.serialNumber = this.value.serialNumber;
     if(this.value.marketType== '2'){
         
            this.$router.push({ name: "RwaAuctionDetail", query });
     }else{
      this.$router.push({ name: "ProductDetail", query });

          }

          
          }else if(this.value.marketType== '2'){
         
      this.$router.push({ name: "Detail", query });
        }else{
           this.$router.push({ name: "Saledetail", query });
        }
      }
      else {
        let query = { };
         query.nftAddress = this.value.nftAddress;
          query.nftId = this.value.nftId;
          query.owner = this.value.owner
         if(this.type == 'rwa' || this.type == 'myRwas' ){
            query.serialNumber = this.value.serialNumber;

            this.$router.push({ name: "RwaCollectDetail", query });
          }
       else if (this.value.marketType== '2'){
         
      this.$router.push({ name: "Detail", query });
        }
        else{

          console.log('wxl ----- 77777777');
          if((this.type == 'showCase' || this.type == 'other') && this.value.nftType == 'ERC1948'){
            this.$router.push({ name: "ProductDetail", query });
          }else{
            this.$router.push({ name: "Saledetail", query });
          }
          
         
        }
        
         

       
      }
    },
    forceUpdate() {
      this.$forceUpdate();
    }
  },
};
</script>


<style lang="scss" scoped>
.v-card.rd {
  // overflow: hidden;
  width: 100% !important;
  min-height: unset;
  // justify-content: center;
  // background-image: linear-gradient(#ffffff, #ffffff),
  //   linear-gradient(#6f58d9, #6f58d9);
  // background-blend-mode: normal, normal;
  // box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  
  &.normalMode{
    border: 1px solid #cccccc;
  }
 &.bord{
 border: 2px solid #7800f4 !important;
  }  

  &:hover  {
    box-sizing: border-box;
    border-radius: 5px;
    border: solid 1px #7800f4;
    
  }

  //  &::v-deep .v-sheet--outlined {
  //    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.09);
  // }

  @media screen and (max-width: 500px ) {

  }

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    // width: 220px;
   width: 100%;
    border-radius: 5px;
  }
}

.v-card.smallCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    // width: 212px;
    width: 100%;
  
    border-radius: 5px;
  }
}
.v-card.smallsmallCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    // width: 220px;
    width: 100%;
    border-radius: 5px;
  }
}
.v-card.showCaseCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    width: 100%;
    // background-image: linear-gradient(#ffffff, #ffffff),
    //   linear-gradient(#ffffff, #ffffff);
    // background-blend-mode: normal, normal;
    // box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }
}

.v-card.bigCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    // width: 280px;
    width: 100%;
    border-radius: 5px;
  }
}
.v-card.filterBigCard {

  @media screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) {
    // width: 298px;
    width: 100%;
    border-radius: 5px;
  }
}



</style>

