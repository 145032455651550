import api from "@/api";


export const getFileSrc =  (storageKey, hash, isVideo) => {
    try{
    	let src;
    	if (isVideo) {
			if(hash)
			{
				src =  api.video.getNftVideo(
					hash
				);
	
			
				return src;
			}
    	   
    	} else {
			if(hash)
			{
				src =  api.image.getNftImg(
					hash
				);
			
				return src;
			}
    	  
    	}
    
    }catch(e){
    	return "";
    }
};