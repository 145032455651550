<template>
  <div :class="{mobile: isMobile,darkBackground1:this.$vuetify.theme.dark}" style="height: 100px;padding-top: 5px">
   
	<v-card-title class="d-block text--title pt-4 px-lg--5 px-3  text-truncate" 
     :class="{darkFont:this.$vuetify.theme.dark,notFull:currentProductImage && value.marketType != '2' && !isMobile }" >{{value.name ? value.name : "No Name"}}</v-card-title
    >
    
	
	  <v-card-text v-if="value.marketType == '6'"  class="mt-n2" style="height:54px">

    </v-card-text>
	<v-card-text v-if="value.marketType == '2' && (owerfrom =='firspublish' || owerfrom =='onsale')&& value.amount!=0" class="d-flex flex-row justify-space-between  " style="height: 54px">
	  <template v-if="value.auction.auctionStatus == '0'"
	  >
	    <div>
	      <div class="text--bid-title text--secondary-rd " style="margin-bottom:-2px;">
	        {{ $t("popupAuctionStartingBid") }}
	      </div>
	      <div class="d-flex flex-row mt-1">
	        <div>
	           <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	        </div>
	        <div
	          :class="{darkFont:this.$vuetify.theme.dark }"  class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;"
	        >
	          {{ value.auction.lastBidPrice  }}
	        </div>
	      </div>
	    </div>
	    <div>
	      <div class="text--sub-title text--secondary-rd" :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	        {{ $t("workCardStartingin") }}
	      </div>
	      <div class="mt-1">
	        <div class="text--number text--primary-rd font-weight-medium " :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	          {{ hour }}h {{ minutes }}m {{ second }}s
	        </div>
	      </div>
	    </div>
	  </template
	  >
	  <template v-else-if="value.auction.auctionStatus == '1'"
	  >
	    <div>
	      <div class="text--bid-title text--secondary-rd" :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	        {{ $t("workCardCurrentBid") }}
	      </div>
	      <div class="d-flex flex-row mt-1">
	        <div>
	           <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	        </div>
	        <div class="ml-2 text--price text--primary-rd font-weight-medium" :class="{darkFont:this.$vuetify.theme.dark }" style="line-height:22px;">
	          {{ value.auction.lastBidPrice}}
	        </div>
	      </div>
	    </div>
	    <div>
	      <div class="text--sub-title text--secondary-rd" :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	        {{ $t("workCardEndingin") }}
	      </div>
	      <div class="mt-1">
	        <div class="text--number text--primary-rd font-weight-medium" :class="{darkFont:this.$vuetify.theme.dark }">
	          {{ hour }}h {{ minutes }}m {{ second }}s
	        </div>
	      </div>
	    </div>
	  </template
	  >
	</v-card-text>
	<v-card-text v-if="value.marketType == '2' && (owerfrom !='firspublish'&&owerfrom !='onsale')&& value.amount!=0" class="d-flex flex-row justify-space-between mt-n4 px-3 " style="height: 54px;padding-top: 10px;">
	  <template v-if="value.auction.auctionStatus == '0'"
	  >
	    <div>
	      <div class="text--bid-title text--secondary-rd " :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	        {{ $t("popupAuctionStartingBid") }}
	      </div>
	      <div class="d-flex flex-row mt-1">
	        <div>
	           <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	        </div>
	        <div
	            class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:this.$vuetify.theme.dark }"
	        >
	          {{ value.auction.lastBidPrice }}
	        </div>
	      </div>
	    </div>
	    <div>
	      <div class="text--sub-title text--secondary-rd" :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	        {{ $t("workCardStartingin") }}
	      </div>
	      <div class="mt-1">
	        <div class="text--number text--primary-rd font-weight-medium " :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	          {{ hour }}h {{ minutes }}m {{ second }}s
	        </div>
	      </div>
	    </div>
	  </template
	  >
	  <template v-else-if="value.auction.auctionStatus == '1'"
	  >
	    <div>
	      <div class="text--bid-title text--secondary-rd" :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	        {{ $t("workCardCurrentBid") }}
	      </div>
	      <div class="d-flex flex-row mt-1">
	        <div>
	           <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	        </div>
	        <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:this.$vuetify.theme.dark }">
	          {{ value.marketPrice }}
	        </div>
	      </div>
	    </div>
	    <div>
	      <div class="text--sub-title text--secondary-rd" :class="{darkFont:this.$vuetify.theme.dark }" style="margin-bottom:-2px;">
	        {{ $t("workCardEndingin") }}
	      </div>
	      <div class="mt-1">
	        <div class="text--number text--primary-rd font-weight-medium" :class="{darkFont:this.$vuetify.theme.dark }">
	          {{ hour }}h {{ minutes }}m {{ second }}s
	        </div>
	      </div>
	    </div>
	  </template
	  >
	</v-card-text>
	<v-card-text  v-if="((value.marketType == '1' || value.marketType == '3' || value.marketType == '4' ) || value.marketType == null || value.marketType == '5')&& (owerfrom =='firspublish' || owerfrom =='onsale' || owerfrom =='offsale')&& value.amount!=0"  class="d-flex flex-row justify-space-between  align-center text-number" style="height:54px;padding-top: 10px;margin-bottom: 14px">
	   <div class="d-flex flex-row">
	        <div >
	              <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	    </div>
	  <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:this.$vuetify.theme.dark }">
	    {{value.marketPrice}}
	  </div>
	   </div>
     
	 
	  <!-- <div v-if="value.saleStatus ==0" > {{ $t("NotListedYet")}}</div> -->
	  
	   
	 
	</v-card-text>
  <v-card-text  v-if="((value.marketType == '1' || value.marketType == '3' || value.marketType == '4' ) || value.marketType == null  || value.marketType == '5')&& value.nftType!= 'ERC1948'&&(owerfrom !='firspublish'&&owerfrom !='onsale'&&owerfrom !='offsale') "  class="d-flex flex-row   justify-space-between mt-lg-3 mt-n1 px-3 align-center text-number"  >
	   <div class="d-flex flex-row" >
	      <div class="lastsale " v-if="selectType ==  '3'">Last sale</div>
	    <div >
	              <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	    </div>
	  <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:this.$vuetify.theme.dark }">
	    {{value.marketPrice}}
	  </div>
	   </div>
	  
	

	 
	   
	  
	 
	
	</v-card-text>
	<v-card-text  v-if="((value.marketType == '1' || value.marketType == '3' || value.marketType == '4' ) || value.marketType == null  || value.marketType == '5')&& value.nftType == 'ERC1948'&&(owerfrom !='firspublish'&&owerfrom !='onsale'&&owerfrom !='offsale') " class="d-flex flex-row   justify-space-between mt-lg-1 mt-n3 px-3 align-center text-number" :style="{ height: (isMobile || value.nftType== 'ERC1948') ? '61px': '46px',position:'reletive'}" >
	   <div class="d-flex flex-row" v-if="value.nftType != 'ERC1948'">
	      <div class="lastsale " v-if="selectType ==  '3'">Last sale</div>
	    <div >
	              <v-img v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	    </div>
	  <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:this.$vuetify.theme.dark }">
	    {{value.marketPrice}}
	  </div>
	   </div>
	   <div v-else style="width: 100%;" >
	<!-- <RwaCardContent :value="value"/> -->
	<div style="display: flex;flex-direction:row;justify-content: space-between;width: 100%;">
   
    <!-- <div  v-if="isMobile &&currentProductImage">
      <img :src="currentProductImage" alt="logo" width="86" height="86"  class="imgUrl"/>
    </div> -->
    <div class="fullWith " :class="{notFull:currentProductImage}">
    
    <v-card-text class="d-flex flex-row  px-0 pb-8 text-number">
      <div class="lastsale " v-if="selectType ==  '3'">Last sale</div>
      <div>
        <v-img :src="getChainByName(value.tokenType).img" alt="logo" width="22px" height="22px" />
      </div>
      <div class="ml-2 text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{ darkFont: this.$vuetify.theme.dark }">
       {{value.marketPrice}}
      </div>
    </v-card-text>
    </div>
    <div  v-if="!isMobile && currentProductImage ">
		<img :src="currentProductImage" alt="logo" width="60" height="60"  class="imgUrl"/>
    </div>
   
  </div>

	   </div>
	   
	  
	 
	
	</v-card-text>
	
	 <v-card-text  v-if="value.amount == 0 && owerfrom !='firspublish' && selectType !=  '3'"  class="d-flex flex-row  mt-1 text-number">
	  <!-- <v-icon size="15">mdi-facebook</v-icon> -->
	    <div >
	              <v-img class="" v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	    </div>
	  <div class="ml-2  text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:this.$vuetify.theme.dark }">
	    {{value.marketPrice}}
	  </div>
	   <img
	     
	         class="sold_out"
	         :class="{sold_out_mobile:isMobile}"
	          src="@/assets/icons/icon_soldout.png"
	          width="95px"
	          height="36px"
	          alt
	        />
	   <!-- <div  class="sold_out" :class="{sold_out_mobile:isMobile}">{{$t("workCardSoldOut")}}</div> -->
	</v-card-text>
	 <v-card-text  v-if="value.amount == 0 && owerfrom =='firspublish' && selectType !=  '3'"  class="d-flex flex-row  mt-1 text-number" style="min-height:50px">
	  <!-- <v-icon size="15">mdi-facebook</v-icon> -->
	    <div >
	              <v-img class="" v-if="value.marketPrice" :src="getChainByName(value.tokenType).img" alt="logo" width="23px" height="23px"/>
	    </div>
	  <div class="ml-2  text--price text--primary-rd font-weight-medium" style="line-height:22px;" :class="{darkFont:this.$vuetify.theme.dark }">
	    {{value.marketPrice}}
	  </div>
	   
	   <!-- <div  class="sold_out" :class="{sold_out_mobile:isMobile}">{{$t("workCardSoldOut")}}</div> -->
	</v-card-text>
	
	
	
	
  </div>
</template>

<script lang='js'>
import api from '@/api'
import RwaCardContent from './RwaCardContent.vue'

export default {
  name: 'MarketplaceCardContentNew',
  components: { RwaCardContent },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isMinPrice:{
      type:String
	},
	owerfrom:{
		 type:String
	},
selectType:{
		 type:Number
	}

  },
 data: function() {
   
   return {
	 currentProductImage:'',
	 images:[],
     // countLogo,
     isStart: false,
     now: "",
     start: "",
     end: "",
     day: 0,
     hour: 0,
     minutes: 0,
     second: 0,
     seconds: "",
   };
 },
 mounted() {
   this.Time(); //调用定时器
   if(this.value.productCardInfo?.previewImgUrls){
                JSON.parse(this.value.productCardInfo.previewImgUrls).forEach(element => {
                  let imgUrl={imageUrl:  api.image.getNftImg(element)}
                this.images.push(imgUrl);

      })
      if(this.images){
        this.currentProductImage=this.images[0].imageUrl;
      }
	}

	
 },
 
 methods: {
   getTime() {
     if(this.value.auction){
       this.now = Date.parse(new Date());
     this.start = this.value.auction.startTime;
     this.end = this.value.auction.endTime;
     // this.start = Date.parse(new Date("2022-01-09 13:48:00"));
     // this.end = Date.parse(new Date("2022-01-09 13:49:00"));
 
     if (this.value.auction.auctionStatus == 0 || this.value.auction.auctionStatus == 1) {
       if (this.now < this.start) {
         this.isStart = false;
         this.seconds = (this.start - this.now) / 1000;
       } else if (this.now < this.end) {
         this.isStart = true;
         this.seconds = (this.end - this.now) / 1000;
       }
     }
     }
 
     if(this.value.couponTime){
        this.now = Date.parse(new Date());
     this.start = this.value.couponTime.couponStartTime;
     this.end = this.value.couponTime.couponEndTime;
     // this.start = Date.parse(new Date("2022-01-09 13:48:00"));
     // this.end = Date.parse(new Date("2022-01-09 13:49:00"));
 
       if (this.now < this.start) {
         this.isStart = false;
         this.seconds = (this.start - this.now) / 1000;
       } else if (this.now < this.end) {
         this.isStart = true;
         this.seconds = (this.end - this.now) / 1000;
       }
     
     }
     
   },
   // 天 时 分 秒 格式化函数
   countDown() {
     let d = parseInt(this.seconds / (24 * 60 * 60));
     this.day = d < 10 ? "0" + d : d;
     let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
     this.hour = h < 10 ? "0" + h : h;
     let m = parseInt((this.seconds / 60) % 60);
     this.minutes = m < 10 ? "0" + m : m;
     let s = parseInt(this.seconds % 60);
     this.second = s < 10 ? "0" + s : s;
   },
   //定时器没过1秒参数减1
   Time() {
     setInterval(() => {
       if (this.seconds > 0) {
         this.seconds -= 1;
         this.countDown();
       }else{
         this.getTime();
       }
     }, 1000);
   },
 },
};
</script>
<style lang='scss' scoped>
.fullWith{
  width: 100%;
}
.notFull{
  width: 60%;

}
.imgUrl{
  border-radius: 10px;
  object-fit: cover;
  position: absolute;
    right: 14px;
    bottom: 14px;

}
.darkBackground1{
  background-color: #272727 !important ; 
}
.text--primary-rd {
  color: #270645;
}
.text--secondary-rd {
  color: #766983;
}
.text--title {
  font-size: 13px;
  line-height: 24px;
  font-weight: bold;
}

.text--sub-title {
  font-size: 12px;
  line-height: 1;
}
.text--number {
  font-size: 15px;
  line-height: 1;
  font-weight: bold !important;
}
.v-divider {
  border-color: #e3e3e3;
}
time{
     position: absolute;
     bottom:0;
    right: 0;
    width: 30px;
	height: 30px;
  img{
    border-radius: 5px;
  }
  }
.text--primary-rd {
  color: #270645;
}
.text--secondary-rd {
  color: #766983;
}
.text--title {
  font-size: 13px;
  line-height: 15px;
  overflow: hidden;
   white-space: nowrap;
  text-overflow: ellipsis;
}
.text--sub-title {
  font-size: 11px;
  line-height: 1;
  text-align: right;
}
.text--bid-title {
  font-size: 11px;
  line-height: 1;
  text-align: left;
}
.text--number {
  font-size: 11px;
  line-height: 2;
}
.text--price{
   font-size: 13px;
  line-height: 1;
}
.v-divider {
  border-color: #e3e3e3;
}
  .sold_out {
  
      color: #ffffff;
      position: absolute;
      right: 0px;
      bottom: 7px;
   }
   .sold_out_mobile{
      width: 85px;
      height: 36px;
   }
   .lastsale{
     margin-right: 8px;
 font-family: Helvetica;
 font-size: 11px;
 font-weight: normal;
 font-stretch: normal;
 letter-spacing: 0px;
 color: #766983;

   }
   .couponPrice{
     text-decoration: line-through;
     font-family: Helvetica;
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 10px;
	letter-spacing: 0;
	color: #766983;
  text-align: right;
   }

   .mobile {
      .text--sub-title {
         // text-align: left;
         white-space:nowrap;
      }
      .text--number {
         white-space:nowrap;
      }
      .v-card__text{
        padding:10px 5px!important;
        
      }
   }
</style>