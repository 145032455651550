<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
      class="clo"
      @click="closeDialog"
      :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
      alt=""
    />
      <div class="title mb-12"  :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_redeem')}}  </div>
      <div class="baseInfo d-flex flex-lg-row flex-column align-center mb-5">
        <img  :src="imgUrl" class="imageUrl"/>
          <div class="ml-lg-4 ml-0 mt-lg-0 mt-5" style="width: 80%;">
              <div class="collectName mb-4"  :class="{darkFont:$vuetify.theme.dark }">{{productInfo.name}}</div>
              <div class="productName" :class="{darkFont:$vuetify.theme.dark }">{{productInfo.previewName}}</div>
              <div class="description" :class="{darkFont:$vuetify.theme.dark }">{{productInfo.extensionDescription}}</div>
              <div class="collectName " :class="{darkFont:$vuetify.theme.dark }"> x 1</div>
          </div>
      </div>
      <div class="number" :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_receive_name')}} <span class="star">*</span></div>
      <div class="box">
          <v-text-field
            ref="desValue"
            v-model="receiptValue"
            label=""
           maxLength="80"
            outlined
     
          >
          </v-text-field>
        </div>
        <div class="number mt-n4" :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_contact_number')}} <span class="star">*</span></div>
      <div class="box">
          <v-text-field
            ref="desValue"
            v-model="phoneNumbetValue"
            label=""
           maxLength="20"
            outlined
     
          >
          </v-text-field>
        </div>
        <div class="number mt-n4" :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_receiving_area')}} <span class="star">*</span></div>
        <div class="d-flex flex-row">
          <div class="countryBox" :class="{ darkFont: $vuetify.theme.dark }">
            <v-select
              :items="CountryList"
              label=""
              item-text="regionName"
        
              v-model="conutryValue"
              return-object
              style="color: white !important"
              @change="countryChange"
              solo
            ></v-select>
          </div>
          <div class="countryBox ml-5" :class="{ darkFont: $vuetify.theme.dark }">
            <v-select
              :items="CityList"
              label=""
              item-text="regionName"
              v-model="cityValue"
              return-object
              style="color: white !important"
                @change="cityChange"
              solo
            ></v-select>
          </div>
        </div>
        <div class="regionBox" :class="{ darkFont: $vuetify.theme.dark }">
            <v-select
              :items="RegionList"
              label=""
              item-text="regionName"
              v-model="regionValue"
              return-object
              style="color: white !important"
              @change="regionChange"
              solo
            ></v-select>
          </div>
          <div class="number mt-n4" :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_detailed_delivery_address')}} <span class="star">*</span></div>
          <div class="box">
          <v-text-field
            ref="desValue"
            v-model="addressValue"
            label=""
           maxLength="80"
            outlined
     
          >
          </v-text-field>
        </div>
        
        <div class="number mt-n4" :class="{darkFont:$vuetify.theme.dark }">{{$t('rwa_deliveryfee')}}：{{productInfo.deliveryfee}} {{productInfo.feeTokenType}} </div>

        
    
<div style="width: 100%;align-items: center;justify-content: center;display: flex">
  <button class="saveBtn" 
   
    :disabled="!isFormValid"
    @click="subCommit">
    {{$t('popupTransferCommit')}}
  </button>
</div>
  

    </v-card>

    <uComponents ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from "@/api";
import { isThisTypeNode } from "typescript";
export default {
  data: function () {
    return {
      imageUrl:'',
      phoneNumbetValue:'',
      addressValue:'',
      conutryValue: null,
      cityValue: null,
      regionValue: null,
      RegionList:[
  

      ],
      CountryList: [
       
      ],
      CityList:[
      
      ],
      receiptValue:'',
      newOpen: true,
      closeIcon: require("@/assets/btn_close.png"),
      darkcloseIcon: require("@/assets/darkimage/btn_close1.png"),
      fullAddress:'',
      productInfo:{}
  
    };
  },
  props: {
    productDetail:{
        type:Object
      },
      editDelivery:{
        type:String
      }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
    isFormValid() {
      return this.receiptValue && 
        this.phoneNumbetValue && 
        this.conutryValue && 
        this.cityValue && 
        this.regionValue && 
        this.addressValue;
    }
  },
 async  mounted() {
  console.log('wxl ---- productDetail',this.productDetail);
  this.CountryList = await this.getRegiondetail()

  let params = {
        nftAddress:this.productDetail.nftAddress,
          nftId:this.productDetail.nftId,
        
      }
      let result =await api.home.getDeliveryInfo(params)
      this.productInfo=result.data
      if(this.productInfo.imgUrl){
     this.imgUrl= this.getImageIpfs(this.productInfo.imgUrl)
     }
    //  如果已经填写过表单，直接跳到支付弹层
     if(this.productInfo.fullAddress){
      if(this.editDelivery=="goEdit"){
        this.addressValue = this.productInfo.deliveryAddress
      this.receiptValue=this.productInfo.deliveryName
      this.phoneNumbetValue=this.productInfo.deliveryPhone
      this.conutryValue = this.CountryList.find(item => item.id == this.productInfo.countryRegionId)
      
      if(this.conutryValue){
        this.CityList = await this.getRegiondetail(this.conutryValue.id)
        this.cityValue = this.CityList.find(item => item.id == this.productInfo.cityRegionId)
      }
      
      if(this.cityValue){
        this.RegionList = await this.getRegiondetail(this.cityValue.id)
        this.regionValue = this.RegionList.find(item => item.id == this.productInfo.areaRegionId)
      }

      }else{
        this.$emit("getOpen", false,'goDetail');
      }


      


     }

  },

  
  methods: {
    async getRegiondetail(parentId =0){
    let regionParams = {
      parentId:parentId

}
let result = await api.home.getRegionInfo(regionParams)

console.log('wxl ---- phoneNumbetValue',this.CountryList);

return result.data

  },
  async countryChange(){
    console.log('选中的国家对象:', this.conutryValue);
    this.CityList = await this.getRegiondetail(this.conutryValue.id)
  },
  async cityChange(){
    console.log('选中的城市对象:', this.cityValue);
    this.RegionList = await this.getRegiondetail(this.cityValue.id)
  },
  async regionChange(){
    console.log('选中的区域对象:', this.regionValue);
  },
  
  
    async subCommit() {
      try {
        let orderParams = {
          countryRegionId: this.conutryValue?.id,
          areaRegionId: this.regionValue?.id,
          cityRegionId:this.cityValue?.id,
          deliveryAddress: this.addressValue,
          deliveryName: encodeURIComponent(this.receiptValue),
          deliveryPhone:  encodeURIComponent(this.phoneNumbetValue),
          fullAddress:this.conutryValue.regionName+ this.cityValue.regionName+this.regionValue.regionName+' '+ this.addressValue,
          nftAddress:this.productDetail.nftAddress,
          nftId:this.productDetail.nftId,
          name:this.productDetail.name
          

        }
        let result = await api.home.deliveryOrder(orderParams)
        if(result.success){
    
          this.$toast("success",this.$t("success"))
         
            this.$emit("getOpen", false,'goDetail');
          
       

        }else{
          this.$toast("error", this.$t("requireFailed"));

        }
        console.log("subCommit",orderParams);
      } catch (error) {
        this.$toast("error", this.$t("requireFailed"));
      }
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
   
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 630px;
  width: 550px;
  padding:30px 50px;
  .star {
  color: red;
  font-size: 18px;
  font-family: "宋体";
  margin-left: 10px;
}

  .title{
      font-family: Helvetica;
      font-size: 25px;
font-weight: bold;
font-stretch: normal;
line-height: 20px;
letter-spacing: 0px;
color: #270645;
  }
  .imageUrl{
      width: 106px;
height: 106px;
border-radius: 5px;
  }
  .collectName{
      width: 78%;
      font-family: Helvetica;
font-size: 14px;
font-weight: normal;
font-stretch: normal;
line-height: 20px;
letter-spacing: 0px;
color: #270645;
  overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  }
  .productName{
      font-family: Helvetica;
font-size: 14px;
font-weight: bold;
font-stretch: normal;
line-height: 20px;
letter-spacing: 0px;
color: #270645;
  }
  .copyBtn{
  width: 150px;
padding: 0 10px;
height: 33px;
background-color: #ffffff;
border-radius: 16px;
border: solid 1px #7800f4;
  font-family: Helvetica;
  font-size: 13px;
font-weight: normal;
font-stretch: normal;
line-height: 20px;
letter-spacing: 0px;
color: #7800f4;
  }
  .description{
      width: 100%;
      font-family: Helvetica;
font-size: 14px;
font-weight: normal;
font-stretch: normal;
line-height: 20px;
letter-spacing: 0px;
color: #766983;
  }
  .saveBtn{
    margin-top: 32px;
      font-family: Helvetica;
      width: 400px;
height: 51px;
background-color: #7800f4;
border-radius: 25px;
  font-size: 20px;
font-weight: bold;
font-stretch: normal;
line-height: 20px;
letter-spacing: 0px;
color: #ffffff;
    
    &:disabled {
      background-color: #666666;
      cursor: not-allowed;

    }
  }
  .receiptName{
      font-family: Helvetica;
      font-size: 13px;
font-weight: normal;
font-stretch: normal;
line-height: 25px;
letter-spacing: 0px;
color: #270645;
  }
  .countryBox{
    width: 215px;
 
    &::v-deep .v-input__slot{
      border-color: rgba(255, 255, 255, 0.24);
    border-style: solid;
    border-width: 1px;

    }
  }
  .regionBox{
    width: 100%;
    &::v-deep .v-input__slot{
      border-color: rgba(255, 255, 255, 0.24);
    border-style: solid;
    border-width: 1px;

    }
  }
  .number{
      font-family: Helvetica;
      font-size: 15px;
font-weight: bold;
font-stretch: normal;
line-height: 20px;
letter-spacing: 0px;
color: #270645;
  margin-bottom: 10px;
  }
  .box {
          position: relative;
             &::v-deep  .v-input__slot {
              height:46px!important;
              min-height: 46px;
              
             
          }
            &::v-deep .v-text-field input{
              max-width: 100%;
              height:46px!important;
              
            }
         
        }
  .font-14{
      font-family:Helvetica;
font-size: 14px;
font-weight: bold;
font-stretch: normal;
line-height: 28px;
letter-spacing: 1px;
color: #270645;
  }

  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .mainContain {
    margin-top: 46px;
    .title-23{
      font-family: Helvetica;
    font-size: 23px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 3px;
    letter-spacing: 0px;
    color: #270645;
    }
    .name{
      font-family: Helvetica;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 30px;
    letter-spacing: 0px;
    color: #270645;
  text-align: center;

    }
    .btn{
      margin-top:35px;
        width: 221px;
        height: 51px;
        background-color: #7800f4;
        border-radius: 25px;
        font-family: Helvetica;
        font-size: 15px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #ffffff;
    }
    .notice{
      width:52px;
      height:52px;
    }
   
  }

  &.mobile {
      .collectName{
        white-space: normal;
      }
    }
}

.sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983, #766983),
    linear-gradient(#270645, #270645) !important;
  background-blend-mode: normal, normal;
  border-radius: 25px;
  opacity: 0.9;
}
.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 110px;
}

</style>
