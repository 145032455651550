var Web3 = require('web3');
const ethers = require('ethers');
const web3Obj = new Web3();
export const getRanHex = size => {
	let result = [];
	let hexRef = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

	for (let n = 0; n < size; n++) {
		result.push(hexRef[Math.floor(Math.random() * 16)]);
	}
	return result.join('');
}




export const timestampToDate = (time) => {
	let date = new Date(time);
	let Y = date.getFullYear() + "-";
	let M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
	let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
	// let h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
	// let m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
	// let s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
	let strDate = Y + M + D;
	return strDate;
}

export const timestampToDateTime = (time) => {
	let date = new Date(time)
	let Y = date.getFullYear() + '-';
	let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
	let strDate = Y + M + D + h + m + s;
	return strDate;
}
export const timestampToDateHour = (time) => {
	let date = new Date(time)
	let Y = date.getFullYear() + '.';
	let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.';
	let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
	let strDate = Y + M + D + h + m;
	return strDate;
}
export const timestampToTimeHour = (time) => {
	let date = new Date(time)
	// let Y = date.getFullYear() + '-';
	// let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
	// let D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
	let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
	//let m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
	//let s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
	let strDate = h;
	return strDate;
}

export const timestampToTimeMinute = (time) => {
	let date = new Date(time)
	// let Y = date.getFullYear() + '-';
	// let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
	// let D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
	//let h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
	let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
	//let s = (date.getSeconds() < 10 ? '0'+date.getSeconds() : date.getSeconds());
	let strDate = m;
	return strDate;
}
export const timestampToTimeSecond = (time) => {
	let date = new Date(time)
	// let Y = date.getFullYear() + '-';
	// let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
	// let D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
	//let h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
	//let m = (date.getMinutes() < 10 ? '0'+date.getMinutes() : date.getMinutes()) + ':';
	let s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
	let strDate = s;
	return strDate;
}

export const getChainFromChannel = (channel) => {
	switch (channel) {
		case 'Uptick':
			return "Uptick Chain Marketplace"

		default:
			return ''
	}

}
export const addDate = (date, days) => {
	let timestamp = date + days * 86400000;
	//let timestamp = date + days * 600000;
	return timestamp;
}
export const addDateToSecond = (date, days) => {
	let timestamp = date + days * 86400;
	// let timestamp = date + days * 600;
	return timestamp;
}

export const toIpfsLink = (ipsfHash) => {
	return ipsfHash;
}
export const toIpfsFullLink = (ipsfHash) => {
	// https://s3.uptick.network/QmWJNUhAgDvcUA2dtJW5bWULpd1ewxDJNSN4KgFy8CcMHz/original
	return  "https://s3.uptick.network/" + ipsfHash + "/original";
}
export const toS3Link = (ipsfHash) => {
	
		return "https://s3.uptick.network/" + ipsfHash +'.json' ;
}
export const toNftS3Link = (ipsfHash) => {
	
	return "https://s3.uptick.network/" + ipsfHash ;
}
export const toMetaLink = (ipsfHash) => {
	return "https://dpx9zbsum7s83.cloudfront.net/" + ipsfHash + "";
}
export const erc1155MetadataUri = (ipsfHash) => {
	return "https://s3.uptick.network/" + ipsfHash +'/{id}.json' ;
}
export const toBaseUri = (subUri) => {

	let prePrefixBaseUrl = process.env.VUE_APP_PREFIX_BASE_URL;
	return prePrefixBaseUrl + subUri;
}


const BigNumber = require('big-number');
export const toUnitValue = (web3Obj, tokenId, OrgValue) => {
	console.log("toUnitValue  ----")
	let env = process.env.VUE_APP_ENVIRONMENT;
	let retValue = 0;
	if (env == "dev") {
		if (tokenId == 2 || tokenId == 8) {
			retValue = (parseFloat(OrgValue) * 1000000);
		} else {
			retValue = web3Obj.utils.toWei(OrgValue, "ether");
		}

	} else {
		if (tokenId == 3 || tokenId == 6) {
			retValue = (parseFloat(OrgValue) * 1000000);
		} else {
			retValue = web3Obj.utils.toWei(OrgValue, "ether");
		}

	}

	return retValue;
}

export const toShowValue = (web3Obj, tokenId, OrgValue) => {
	let env = process.env.VUE_APP_ENVIRONMENT;
	let retValue = 0;
	if (env == "dev") {

		if (tokenId == 2 || tokenId == 8) {
			retValue = parseFloat(OrgValue) / 1000000;
		} else {
			retValue = web3Obj.utils.fromWei(OrgValue);
		}
	} else if (env == "pro") {

		if (tokenId == 3 || tokenId == 6) {
			retValue = parseFloat(OrgValue) / 1000000;
		} else {
			console.log("wxl -- tokenId", tokenId, OrgValue)
			retValue = web3Obj.utils.fromWei(OrgValue);
		}
	}
	return retValue.toString();
}

let tokenId2IndexMapDev = {
	0: 0, 1: 1, 2: 2, 6: 3, 8: 2
}
let tokenId2IndexMapPro = {
	0: 2, 1: 3, 3: 4, 5: 1, 6: 0
}
export const tokenId2Index = (tokenId) => {
	let env = process.env.VUE_APP_ENVIRONMENT;
	if (env == "dev") {
		return tokenId2IndexMapDev[parseInt(tokenId)]
	} else {
		console.log("tokenId2IndexMapPro", tokenId, tokenId2IndexMapPro[parseInt(tokenId)])
		return tokenId2IndexMapPro[parseInt(tokenId)]
	}

}
let maxFeeBips = 1000
export const getMaxFeeBips = (totalRoyalty) => {
	return totalRoyalty * 100;
}

export const getFeePrice = (orgPrice, tokenId, tradeCost, type) => {


	if (!orgPrice) {
		orgPrice = "1"
	}
	let price = parseFloat(orgPrice) * 0.01;
	price = number_format(price.toFixed(4))
	let bigOrgPrice = toUnitValue(web3Obj, tokenId, orgPrice + "")
	let tradeCostPrice;

	let tradeCostToWei;
	let finalPrice;
	// 计算成本价开始
	if (type == 'auction') {
		tradeCostToWei = toShowValue(web3Obj, tokenId, tradeCost.toString()) * 1.5
		tradeCostToWei = parseFloat(Math.ceil((tradeCostToWei) * 10000)) / 10000
		tradeCostPrice = Math.ceil((tradeCost * 1.5) / bigOrgPrice * 100) * 100
	} else {
		tradeCostToWei = toShowValue(web3Obj, tokenId, tradeCost.toString())
		tradeCostToWei = parseFloat(Math.ceil((tradeCostToWei) * 10000)) / 10000;
		tradeCostPrice = Math.ceil(tradeCost / bigOrgPrice * 100) * 100
	}
	// 计算成本价结束

	// 比较成本价和单价的1%，取两者的较大值
	if (tradeCostToWei > price) {
		finalPrice = tradeCostToWei

	} else {
		finalPrice = price
		tradeCostPrice = 100  //成本价比价格低，就取单价的1%，万分比是100
	}

	let feeJson = {
		tradeCostPrice: tradeCostPrice,
		feePrice: finalPrice

	}
	// 计算1%和成本费tradeCost 取大值 toUnitValue(web3Obj,tokenId,volume.toString())
	return feeJson;
}
var number_format = function (number, n) {
	n = n ? parseInt(n) : 8;
	if (n <= 0) return Math.round(number);
	number = Math.round(number * Math.pow(10000, n)) / Math.pow(10000, n);
	return number;
}
export const getTokenId = () => {
	var date = Date.now();
	var str = Math.floor((Math.random() + Math.floor(Math.random() * 5 + 1)) * Math.pow(10, 5));
	str = date + "" + str
	return str

}
export const getCurrentChain = () => {
	let currentChain = process.env.VUE_APP_MARKET_TYPE
	if (currentChain == 'POLYGON') {
		return 'Polygon'
	} else if (currentChain == 'BSC') {
		return 'BSC'
	} else if (currentChain == 'ARBITRUM') {
		return 'Arbitrum'
	}
}
export const getSettingArr = () => {
	const json = localStorage.getItem("key_user");
	let address = JSON.parse(json);
	let StorageListObj = JSON.parse(localStorage.getItem('SettingSelectList_' + address.did))
	let pushTypes = []
	if (StorageListObj) {
		if (StorageListObj.length > 0) {
			for (let i = 0; i < StorageListObj.length; i++) {
				pushTypes.push(StorageListObj[i].key)
			}
			return pushTypes.join(',')
		} else {
			return "-1"
		}

	} else {
		return ''
	}


}

export function getCrossID(crossNFTTxParam) {

	let crossString = crossNFTSerial(crossNFTTxParam);
	console.log("###xxl cross string :(%s)", crossString);
	return web3Obj.utils.sha3(crossString);

}

export function getBrageType(nftType) {
debugger
	switch (nftType) {
		case 'ERC721':
			return 2

		case 'ERC1155':
			return 3

		default:
			return ''

	}
}

export function getStatus(status){
	switch (status) {
	  case 0:
		return t('orderStateUnpay')
		case 1:
		return this.$t('rwa_pay_pending')
		case 2:
		return this.$t('rwa_pay_failed')
		case 3:
		return this.$t('rwa_pay_deliver')
		case 4:
		return this.$t('rwa_pay_receipt')
		case 5:
		return this.$t('rwa_pay_done')

 
	
	  default:
		break;
	}

  }


export function convertFromERC2Metadata(
    eType,crossID,timespan,tokenAddress,amounts,ids,fromChainId,toChianId
){
    console.log('====================================convertFromERC2Metadata');
    console.log(eType,crossID,timespan,tokenAddress,amounts,ids);
    console.log('====================================');
    let retData = "0x";
    retData += number2Byte32Data(eType);
    retData += crossID.substring(2)
    retData += number2Byte32Data(timespan)

    if(eType == 0){                         //0:native
        for( var i = 0 ;i < amounts.length ;i ++){
            retData += number2Byte32Data(amounts[i])
        }
    }else if(eType == 1){                   //1:erc20
        retData += address2Byte32Data(tokenAddress)
        for( i = 0 ;i < amounts.length ;i ++){
            retData += number2Byte32Data(amounts[i])
        }
    }else if(eType == 2 || eType == 4){    //2:erc721 4:erc4907
        retData += address2Byte32Data(tokenAddress)
        for( i = 0 ;i < ids.length ;i ++){
            retData += number2Byte32Data(ids[i])
        }
    }else if(eType == 3){
        retData += address2Byte32Data(tokenAddress)
        // retData += number2Byte32Data(amounts)
        for( i = 0 ;i < ids.length ;i ++){
            retData += number2Byte32Data(ids[i])
        }
        for( i = 0 ;i < amounts.length ;i ++){
            retData += number2Byte32Data(amounts[i])
        }
    }else{
        console.log("not support erc");
    }
	retData += number2Byte32Data(fromChainId)
	retData += number2Byte32Data(toChianId)
    return retData;

}

export function number2Byte32Data(orgData){
    let bTemp = ethers.BigNumber.from(orgData)
    return ethers.utils.hexZeroPad(bTemp.toHexString(), 32).substring(2)
}

function address2Byte32Data(orgData){
    let bTemp = orgData.substring(2)
    return ethers.utils.hexZeroPad("0x"+bTemp, 32).substring(2)
}


function crossNFTSerial(crossNFTTxParam) {


	let keys = ["nftID", "fromChainID", "toChainID", "toAddress", "timespan"];
	let keyLen = keys.length;
	let result = "";

	for (let i = 0; i < keyLen; i++) {

		let isKeyExist = Object.prototype.hasOwnProperty.call(crossNFTTxParam, keys[i])

		if (!isKeyExist) { return ""; }

		let isArray = Array.isArray(crossNFTTxParam[keys[i]])

		if (!isArray) {
			result += crossNFTTxParam[keys[i]].toString();
		} else {
			result += crossNFTTxParam[keys[i]].toString();
		}
	}
	return result.toLowerCase();

}


