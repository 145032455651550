<template>
  <div id="container">
    <v-card
      :class="{ card: true, mobile: isMobile }"
      @dragleave="dragContain"
      @dragover="leaveContain"
      @drop="endContain"
    >
      <img
        class="clo"
        @click="closeDialogs"
        :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
        alt
      />
      <div class="card_img d-flex flex-column mr-lg-16">
        <div
          class="title mt-3 mb-2"
          style="font-size: 23px !important"
          :class="{ darkFont: $vuetify.theme.dark }"
        >
          {{ $t("create_collection") }} <template v-if="createDrop!='createDrop'">
			  {{ createType }}
		  </template>
        </div>
        <div class="des mb-2" :class="{ darkFont: $vuetify.theme.dark }">
			<template v-if="createDrop=='createDrop'">
				{{ $t("Create_collection_drop_desc") }}
			</template>
			<template v-else>
				 {{ $t("create_collection_desc") }}
			</template>
         
        </div>

        <img
          v-if="uploadFileUrl"
          class="clo"
          width="31px"
          height="31px"
          @click.stop="closeDialog"
          :src="$vuetify.theme.dark ? darkcloseIcon : closeIcon"
          alt=""
        />

        <div
          class="d-flex flex-column justify-center align-center creatimg"
          :class="{ darkBorder: this.$vuetify.theme.dark,imgDrap:isDragImg }"
        >
          <!-- .jpg,.png,.gif,.mp4 -->
          <FIleInput
            accept=".jpg,.jpeg,.png,.gif"
            @change="change"
            v-if="!uploadFileUrl"
            :key="fileInputKey"
          >
            <div
              class="uploadbox drop_area"
              :class="{ dropwait: isDrop }"
              @drop="enentDrop"
              @dragleave="dragleave"
              @dragenter="dragleave"
              @dragover="dragleave"
            >
              <div class="drop_txt"></div>
              <template>
                <v-btn
                  class="uc"
                  width="161px"
                  height="40px"
                  style="margin-top: 100px"
                  rounded
                  >{{ $t("createChooseFile") }}
                </v-btn>
                <div
                  class=" des mt-5"
                  style="font-size: 13px"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  {{ $t("createCollectionLimit") }}
                </div>
                <div
                class="des mt-3"
                  style="font-size: 13px"
                  :class="{ darkFont: $vuetify.theme.dark }"
                >
                  {{ $t("createLimitNotVideo") }}
                </div>
              </template>
              <div class="progress_area" v-if="isDrop">
                <span :style="{ width: progressValue + '%' }"></span>
              </div>
            </div>
          </FIleInput>
          <video
            v-else-if="fileType == 'video/mp4'"
            :src="localFileUrl || uploadFileUrl"
            width="100%"
            height="100%"
            contain
            loop
            controls
            controlslist="nodownload"
          ></video>
          <v-img
            v-else
            :src="localFileUrl || uploadFileUrl"
            width="100%"
            height="100%"
            contain
          >
           <div
              class="uploadbox drop_area"
              :class="{ dropwait: isDrop,noborder:this.$vuetify.theme.dark }"
              @drop="enentDrop"
              @dragleave="dragleave"
              @dragenter="dragleave"
              @dragover="dragleave"
              @dragend="endContain"
            >
            </div>
          </v-img>
        </div>

        <div>
          <div
            class="title mt-4 mb-2"
            :class="{ darkFont: $vuetify.theme.dark }"
          >
            {{ $t("collection_name") }} <span class="star">*</span>
          </div>
          <div class="box">
            <v-text-field
              ref="nameValue"
              v-model="nameValue"
              :rules="[rules.nameRules]"
              label=""
              outlined
              :key="refresh_key"
            >
            </v-text-field>
          </div>
        </div>
        <div>
          <div
            class="title mb-2"
            :class="{ darkFont: $vuetify.theme.dark }"
          >
            {{ $t("popupAccountAppealDescription") }}<span class="star">*</span>
          </div>
          <div class="box" >
            <v-textarea
              ref="descriptionValue"
              :rules="[rules.descRules]" 
              outlined
              name="input-7-4"
              label=""
              value=""
              v-model="descriptionValue"
              :key="refresh_key"
            ></v-textarea>
          </div>
        </div>
        <div>
          <div
            class="title mt-n2 mb-2"
            :class="{ darkFont: $vuetify.theme.dark }"
          >
            {{ $t("createCategories") }}
          </div>
          <div class="box" :class="{ darkFont: $vuetify.theme.dark }">
            <v-select
              :items="CategoryList"
              :label="this.$t('otherpageTabsPageArtwork')"
              item-text="texts"
              item-value="value"
              v-model="categoryValue"
              style="color: white !important"
              solo
            ></v-select>
          </div>
        </div>
        <div>
          <div
            class="title mb-2"
            :class="{ darkFont: $vuetify.theme.dark }"
          >
            {{ $t("shortUrl") }}<span class="star">*</span>
          </div>
          <div class="des" :class="{ darkFont: $vuetify.theme.dark }">
            {{ $t("url_describe") }}
          </div>
          <div class="url mt-2 mb-2" :class="{ darkFont: $vuetify.theme.dark }">
           {{host + '/collection/'}} 
          </div>
          <div class="box">
            <v-text-field
              ref="shortUrlValue"
              v-model="shortUrlValue"
              :rules="urlRules"
              label=""
              outlined
              :key="refresh_key"
              maxlength="50"
              :disabled="isEdit"
            ></v-text-field>
          </div>
        </div>
        <div class="copyright box d-flex flex-row">
          <div class="checkbox mt-n5">
            <v-checkbox v-model="checkbox" label=""></v-checkbox>
          </div>
          <div class="content" :class="{ darkFont: $vuetify.theme.dark }">
            {{ $t("createPleaseEnsure") }}
            <p :class="{ darkFont: $vuetify.theme.dark }">
              {{ $t("createAgreed")
              }}<span style="cursor: pointer" @click="toTerms"
                >《{{ $t("createCopyright") }}》</span
              >
            </p>
          </div>
        </div>
        <div class="footer box">
          <v-btn class="nosub" height="51px" v-if="subDisabled">
            {{ $t("popupTransferCommit") }}
          </v-btn>
          <button class="sub" height="51px" @click="subCommit" :disabled="showMask"  v-else>
            {{ $t("popupTransferCommit") }}
          </button>
          <span class="price mt-4"
            >{{ $t("balanceCount") }}: {{ transferValue }} {{chain}}</span
          >
           <div
            class="d-flex flex-row"
            v-if="transferValue == 0 && isShowFeacet"
            style="justify-content: center"
          >
            <div class="faucet">{{ $t("NotEnoughFee") }}</div>
            <div class="uptick" @click="toFaucet">
              {{ $t("request_token") }} >
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <div class="mask d-flex flex-column" v-if="showMask">
      <img class="img" src="@/assets/loading.gif" alt="" />
       <!-- <div style="color:white">创建中，请稍等...</div> -->
    </div>
    <PromptBox ref="promptBox" @commit="promptBoxCommit"></PromptBox>

	
    <uComponents ref="ucom"></uComponents>
  </div>
</template>

<script>

import { getFileSrc } from "@/utils/file";
import FIleInput from "@/components/FIleInput.vue";
import api from "@/api";
import { toIpfsLink, toBaseUri, toS3Link ,erc1155MetadataUri,toIpfsFullLink} from "../../utils/helper";
import PromptBox from "@/components/PromptBox.vue";
import { abi, bytecode } from "@/metaMask/evm/artifact/Uptick721.json";
import {
  abi as abi_1155,
  bytecode as bytecode_1155,
} from "@/metaMask/evm/artifact/Uptick1155.json";
import {
  abi as abi_LazyNFT,
  bytecode as bytecode_LazyNFT,
} from "@/metaMask/evm/artifact/LazyNFT.json";
import {
  abi as abi_1948,
  bytecode as bytecode_1948,
} from "@/metaMask/evm/artifact/LazyNFT1948.json";

import {
  connect,
  wallectConnectSendTransaction,
  isWalletConnect,
} from "@/metaMask/evm/handler/common";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { getGasPriceAndGasLimit } from "@/metaMask/evm/handler/base";
import { initProvider} from "@/walletConnectU";


const Web3 = require("web3");
const uptickUrl = window.location.protocol + "//" + window.location.host + "/uptick";

const UPLOAD_KEY = "UPLOAD";
let fileUrl = "";
let imageUrl = "";
export default {
  components: { FIleInput, PromptBox },
  props: {
    createType: {
      type: String,
    },
    createDrop: {
      type: String,
    },
    NFTInfo: {
      type: Object,
    },
  },
  data: function () {
    return {
      isPay: false,
      refresh_key: 0,
      open: false,
      feeValueFormat: "0",
      transferValue: "",
      feeValue: "",
      isEdit: false,
      subDisabled: false,
      nameValue: "",
      descriptionValue: "",
      sum: 1,
	
      uploadFileHash: {
        imgUrl: "",
        name: "　",
        did: "",
        ownerName: "",
        ownerPhoto: "",
      },
      fileInputKey: 0,
      uploadFileUrl: "",
      uploadImageUrl: "",
      showUploadImage: false,
      localFileUrl: "",
      fileType: "",
      showMask: false,
      commit_msg: "",
      methodType: 11,
      isDrop: false,
      isDropImg: false,
      progressValue: 0,
      checkbox: false,
      closeIcon: require("@/assets/btn_close.png"),
      darkcloseIcon: require("@/assets/darkimage/btn_close1.png"),
      CategoryList: [
        {
          texts: this.$t("otherpageTabsPageArtwork"),
          value: 1,
        },
        {
          texts: this.$t("market_Photograph"),
          value: 2,
        },
        {
          texts: this.$t("market_PFP"),
          value: 3,
        },
        {
          texts: this.$t("amCreateCollectibles"),
          value: 4,
        },
      ],
       urlRules: [
        (v) => !!v || "URL format error",

        (v) => /^[0-9a-z_]{1,}$/.test(v) || "URL format error",
      ],
     

       rules: {
        nameRules: (value) => {
          if(!value){
            return "Please choose a name for your NFT"
          }
           if(value &&value.length > 80){
            return "Name must be less than 80 characters"
          }

         
        	const pattern = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDE4F\uDE80-\uDEFF]/g
          return !pattern.test(value) || "Cannot contain emoticons";
        },
        descRules:(value) =>{
          if(!value ){
            return this.$t("desRulers")
          }

         
        	const pattern = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDE4F\uDE80-\uDEFF]/g
          return !pattern.test(value) || "Cannot contain emoticons";
        },
         EditionValue: (value) => {
          // const pattern = /^([1-9][0-9]{0,2}|100)$/;
          const pattern = /^[0-9]$|^[0-4][0-9]$|^50$/;
          return pattern.test(value) || "Invalid amount for sale";
        },
      
      },
     
      categoryValue: "",
      shortUrlValue: "",
      contractAddress: "",
      contractImageUrl: "",
      transactionHash: "",
      attribute: 2,
      isDragImg: false,
       chain:'UPTICK',
       lazySignAddress:'',
	   lazy1948SignAddress:'',
           isShowFeacet:true,
           host:'',
           gasPrice:'',
    };
  },
  watch: {
    nameValue: {
      handler: function (newValue) {
        this.uploadFileHash.name = newValue;
        this.submitStatus();
      },
      immediate: true,
    },
    uploadFileUrl: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
    descriptionValue: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },
    shortUrlValue: {
      handler: function () {
        this.submitStatus();
      },
      immediate: true,
    },

    checkbox: {
      handler() {
        this.submitStatus();
      },
      immediate: true,
    },
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
//获取当前域名
  this.host =this.gethost()
    // 当前市场
   let currentChain = `${this.$env.VUE_APP_MARKET_TYPE}`
   let currentSymbol = `${this.$env.VUE_APP_ADD_NETWORK_CHAIN_SYMBOL}` 
      if(currentChain == 'POLYGON'){
        this.chain = 'MATIC'
            this.isShowFeacet = false
      }else if(currentChain == 'BSC'){
        this.chain = 'BNB'
         this.isShowFeacet = false  
      }else if(currentChain == 'ARBITRUM'){
        this.chain = currentSymbol
         this.isShowFeacet = false  
      }
   

    let balance = await this.$wallet.getMyBalance();
    let mount = balance.format;
    this.transferValue = mount;
    let res2 = await api.home.getFee();
    this.lazySignAddress = res2.data.lazySignAddress
	this.lazy1948SignAddress=res2.data.lazy1948SignAddress;
	console.log("lazy1948SignAddress==",this.lazy1948SignAddress);
    if (this.createType == "ERC721") {
      this.feeValueFormat = Number(res2.data.erc721OnsaleUptickAssetFee);
    } else if (this.createType == "ERC1155") {
      this.feeValueFormat = Number(res2.data.erc1155OnsaleUptickAssetFee);
    }

    if (this.NFTInfo && this.NFTInfo.linkUrl != "") {
      debugger;
      this.isEdit = true;

      if (this.NFTInfo.category == "2") {
        this.categoryValue = 1;
      } else if (this.NFTInfo.category == "4") {
        this.categoryValue = 2;
      } else if (this.NFTInfo.category == "1") {
        this.categoryValue = 3;
      } else if (this.NFTInfo.category == "3") {
        this.categoryValue = 4;
      }

      // this.categoryValue = Number(this.NFTInfo.category) ;
      this.uploadFileUrl = this.getImageIpfs(this.NFTInfo.collectionImgUrl);
      this.nameValue = this.NFTInfo.name;
      this.shortUrlValue = this.NFTInfo.linkUrl;
      this.uploadFileHash.imgUrl = this.getImageIpfs(
        this.NFTInfo.collectionImgUrl
      );
      //    this.folderId = nftInfo.id
      imageUrl = this.NFTInfo.collectionImgUrl;
      this.contractImageUrl = this.NFTInfo.collectionImgUrl;
	  
      if (this.NFTInfo.description) {
        this.descriptionValue = this.NFTInfo.description.replace(
          /<br\s*\/?>/g,
          "\n"
        );
      }
    }

    // 获取gas费用
   let gasSetting = await getGasPriceAndGasLimit()
   this.gasPrice =gasSetting.gasPrice
  },

  methods: {
    toFaucet() {
      this.$router.push({ name: "Faucet" });
      this.$emit("getOpen", false);
    },
    verify() {
      // let mountVer = this.$refs.amountValue.validate(true);
      let nameVer = this.$refs.nameValue.validate(true);
      let emailVer = this.$refs.descriptionValue.validate(true);
      let shortUrlVer = this.$refs.shortUrlValue.validate(true);

      // !mountVer ? this.$refs.amountValue.focus() : "";
      !nameVer ? this.$refs.nameValue.focus() : "";
      !emailVer ? this.$refs.descriptionValue.focus() : "";
      !shortUrlVer ? this.$refs.shortUrlValue.focus() : "";

      return nameVer && emailVer && shortUrlVer;
    },
    submitStatus() {
      this.subDisabled = !(
        this.nameValue &&
        this.uploadFileUrl &&
        this.descriptionValue &&
        this.checkbox &&
        this.shortUrlValue &&
        parseFloat(this.feeValueFormat) < parseFloat(this.transferValue)
      );
    },
     gethost(){
      let url = window.location.href 
 
     var urlstr = url.split("/"); 
    var urls = '';
    if (urlstr[2]) {
        urls = urlstr[0]+'//'+urlstr[2];
    }
      return urls
    },
    async subCommit() {
      if (!this.verify()) {
        return;
      }
      this.showMask = true;

      // 检查linkurl
      let checkUrlParams = {
        linkUrl: this.shortUrlValue,
      };
      if (this.NFTInfo && this.NFTInfo.linkUrl != "") {
        checkUrlParams.nftAddress = this.NFTInfo.nftAddress;
      }

      let checkUrl_res = await api.home.checkLinkUrl(checkUrlParams);
      if (!checkUrl_res.success) {
        this.$toast("error", checkUrl_res.msg);
        this.showMask = false;
        this.isPay = false;
        return;
      }

      // get attribute  3-artwork 4-collectibles 5-photography 7-video
      if (this.categoryValue === 1) {
        this.attribute = 2;
      } else if (this.categoryValue === 2) {
        this.attribute = 4;
      } else if (this.categoryValue === 3) {
        this.attribute = 1;
      } else if (this.categoryValue === 4) {
        this.attribute = 3;
        this.methodType = 12;
      }
     

      try {
        this.isPay = true;
        let contractaddress,
          web3,
          proofContract,
          accounts,
          proof,
          transactionHash;

 let hasWalletConnect = isWalletConnect();
          if (hasWalletConnect) {
          	let provider=await initProvider();
            await provider.enable();
            //  Create Web3
            web3 = new Web3(provider);
          } else {
            web3 = new Web3(window.ethereum);
          }
		  	accounts = await web3.eth.getAccounts();
		 proofContract = new web3.eth.Contract(abi);

     // 上传metadata
     let ipfs = toIpfsFullLink(imageUrl);

let ipfsParams = {
  thumbnail_uri: ipfs,
  banner_uri: ipfs,
  avatar_uri: ipfs,
  tile_uri: ipfs,
  name: this.uploadFileHash.name,
  description: this.descriptionValue,
};
console.log("wl-   collect create",ipfsParams);

let collectionMetadata = await api.image.uploadJSON2IPFS(ipfsParams);
if (!collectionMetadata.data) {
  return;
}

		if(this.createDrop=="createDrop"){
			let gasLimit0="0x7a1200"
			 let currentChainID =  window.ethereum.networkVersion
			
			if(currentChainID=="42161"){
				// 如果是arbitrum 生产，gasLimit需要提高 8000,0000,其他链是 400w
				gasLimit0="0x4c4b400"
			}
			
			 proofContract = new web3.eth.Contract(abi_LazyNFT);
			 proof = await proofContract
			   .deploy({
			     data: bytecode_LazyNFT,
			     arguments: [this.nameValue, "", this.lazySignAddress],
			   })
			   .send(
			     {
			       from: accounts[0],
			       gasPrice: this.gasPrice ,
			       gasLimit: gasLimit0,
			     },
			     function (e, contract) {}
			   )
			   .on("receipt", function (receipt) {
			     transactionHash = receipt.transactionHash;
			     contractaddress = receipt.contractAddress.toLowerCase();
			   });
			   
		} else{
			let gasLimit0="0x3D0900"
			 let currentChainID =  window.ethereum.networkVersion

			if(currentChainID=="42161"){
				// 如果是arbitrum 生产，gasLimit需要提高
				gasLimit0="0x4c4b400"
			}
			if (this.createType == "ERC721") {
        console.log('wxl ----- accounts[0]',accounts[0]);
        debugger
			  proofContract = new web3.eth.Contract(abi);
    
			  proof = await proofContract
			    .deploy({
			      data: bytecode,
			      arguments: [this.nameValue, ""],
			    })
			    .send(
			      {
			        from: accounts[0],
			        gasPrice: this.gasPrice ,
			        gasLimit: gasLimit0,
			      },
			      function (e, contract) {}
			    )
			    .on("receipt", function (receipt) {
			      transactionHash = receipt.transactionHash;
			      contractaddress = receipt.contractAddress;
			    })
          .on('error', (error) => {
            console.error(error);
            // 合约部署失败时的处理逻辑
          })
          
        console.log("wxl --- proof",proof);
				
				
			} else if(this.createType == "ERC1155") {
        let metadataUrl = erc1155MetadataUri(collectionMetadata.data.data)
        console.log("erc 1155 metadata Uri",metadataUrl);
        debugger
			  proofContract = new web3.eth.Contract(abi_1155);
			  proof = await proofContract
			    .deploy({
			      data: bytecode_1155,
			      arguments: [this.nameValue, "", metadataUrl],
			    })
			    .send(
			      {
			        from: accounts[0],
			        gasPrice: this.gasPrice,
			        gasLimit: gasLimit0,
			      },
			      function (e, contract) {}
			    )
			    .on("receipt", function (receipt) {
			      transactionHash = receipt.transactionHash;
			      contractaddress = receipt.contractAddress;
			    });
			}else if(this.createType == "ERC1948"){
				let gasLimit0="0x7a1200"
				 let currentChainID =  window.ethereum.networkVersion
				
				if(currentChainID=="42161"){
					// 如果是arbitrum 生产，gasLimit需要提高 8000,0000,其他链是 400w
					gasLimit0="0x4c4b400"
				}
				
				 proofContract = new web3.eth.Contract(abi_1948);
				 console.log("abi_1948长度==",this.lazy1948SignAddress)
				 proof = await proofContract
				   .deploy({
				     data: bytecode_1948,
				     arguments: [this.nameValue, "", this.lazy1948SignAddress],
				   })
				   .send(
				     {
				       from: accounts[0],
				       gasPrice: this.gasPrice ,
				       gasLimit: gasLimit0,
				     },
				     function (e, contract) {}
				   )
				   .on("receipt", function (receipt) {
				     transactionHash = receipt.transactionHash;
				     contractaddress = receipt.contractAddress.toLowerCase();
				   });
				   
			}
		}
    
	
        let param = {
          imgUrl: imageUrl,
          name: encodeURIComponent(this.nameValue),
          description: encodeURIComponent(this.descriptionValue),
          metaData: toS3Link(collectionMetadata.data.data),
          owner: this.$store.state.did,
          linkUrl: this.shortUrlValue,
          nftType: this.createType,
          category: this.attribute,
        };
        
        if (this.NFTInfo && this.NFTInfo.linkUrl != "") {
          param.nftAddress = this.NFTInfo.nftAddress;
        } else {
          param.nftAddress = contractaddress;
		  this.contractAddress=contractaddress
        }
		if(this.createDrop=="createDrop"){
			param.contractType="LAZYN_COLLECTION"
		}
    if(this.createType == 'ERC1948'){
      	param.contractType="PRODUCT_CARD_COLLECTION"
      
    }
        let deploy_res = await api.home.nftDeploy(param);

        if (deploy_res.success == true) {
          this.showMask = false;
         
		if(this.createDrop=="createDrop"){
		  	 // 投放合约创建成功后，跳转到投放成功编辑页
      //  this.opendropSuccessDialog=true
      let params = {
        contractImageUrl:this.contractImageUrl,
         contractAddress :this.contractAddress
      }
        this.$emit("getOpen", false,params);
		  }else{
         this.$emit("getOpen", false);
			     window.eventBus.$emit("collectionEdit");
		  }

       this.isPay = false
       
		  
		 
        } else {
          this.$toast("error", deploy_res.msg);
          this.showMask = false;
        }
      } catch (e) {
        console.log("wxl --- e",e);
        this.$toast("error", this.$t("requireFailed"));
        this.showMask = false;
        this.isPay = false;
      }
    },

    closeDialogs() {
      this.$emit("getOpen", false);
    },
    toCreate(createType) {
      this.$router.push({
        name: "CreateNft",
        query: { createType: createType },
      });
      this.$emit("getOpen", false);
    },
    async change(file) {
      // JPG,PNG,GIF,MP4
      let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF".indexOf(ft.toUpperCase()) == -1) {
        // JPG,JPEG,PNG,GIF,MP4
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
        return;
      }

      this.isDrop = true;
      this.fileInputKey++;

      this.fileType = file.type;

      if (file.type == "video/mp4") {
        if (file.size > 20971520) {
          this.isDrop = false;
          this.$toast("error", this.$t("video_not_support"));
          return;
        }
        this.showUploadImage = true;
        this.categoryValue = 4;
      } else {
        if (file.size > 20971520) {
          this.isDrop = false;
          this.$toast("error", this.$t("image_not_support"));
          return;
        }
      }
      let path = await api.image.uploadImage(file, this.onUploadProgress);

      if (!path) {
        this.isDrop = false;
        // this.$toast("error", res.data.msg);
        this.showUploadImage = false;
        if (this.categoryValue == 4) {
          this.categoryValue = 1;
        }
        return;
      }
      imageUrl = path.data.data;
      this.contractImageUrl = path.data.data;
      this.uploadFileHash.imgUrl = path.data.data;
      this.uploadFileUrl = this.getImageIpfs(imageUrl);
      this.isDrop = false;
      this.progressValue = 0;

      this.localFileUrl = window.URL.createObjectURL(file);
      this.uploadFileHash.localImgUrl = this.uploadFileUrl;
    },
    async imgChange(file) {
      // JPG,PNG,GIF
      let ft = file.name.substr(file.name.lastIndexOf(".") + 1);
      if ("JPG,JPEG,PNG,GIF".indexOf(ft.toUpperCase()) == -1) {
        this.$toast("error", this.$t("file_type_not_support"));
        this.fileInputKey++;
        return;
      }

      this.isDropImg = true;
      let path = await api.image.uploadImage(file, this.onUploadProgress);
      //let { path } = await this.ipfsAddFile(file);
      this.fileInputKey++;
      if (!path) {
        this.isDropImg = false;
        this.isShowFalse = true;
        // this.falseTitle = res.data.msg;
        return;
      }
      imageUrl = path.data.data;
      this.contractImageUrl = path.data.data;
      this.uploadFileHash.imgUrl = imageUrl;
      this.uploadImageUrl = await getFileSrc(UPLOAD_KEY, path.data.data);
      this.showUploadImage = false;
      this.isDropImg = false;
    },
    closeDialog() {
      this.uploadFileUrl = "";
      this.imgUrl = "";
      this.showUploadImage = false;
      this.uploadImageUrl = "";
      this.uploadFileHash.imgUrl = "00";
      if (this.categoryValue == 4) this.categoryValue = 1;
    },
    closeImgDialog() {
      this.uploadImageUrl = "";
      this.imgUrl = "";
      this.uploadFileHash.imgUrl = "00";
      if (this.fileType == "video/mp4" && this.uploadFileUrl) {
        this.showUploadImage = true;
        this.categoryValue = 4;
      } else {
        this.showUploadImage = false;
      }
    },
    promptBoxCommit(type) {
      if (type == 1) {
        this.$router.push({ name: "PersonalPage" });
        sessionStorage.setItem("PersonalPage_Tabs", 1);
      }
    },
    toTerms() {
      if (this.$vuetify.lang.current == "en") {
        let routeUrl = this.$router.resolve({
          path: "/term",
          query: { id: 96 },
        });
        window.open(routeUrl.href, "_blank");
      } else {
        let routeUrl = this.$router.resolve({
          path: "/term_zh",
          query: { id: 96 },
        });
        window.open(routeUrl.href, "_blank");
      }
    },

    enentDrop(e) {
      this.isDragImg = false
      e.stopPropagation();
      e.preventDefault(); //必填字段
      let file = e.dataTransfer.files[0];
      this.change(file);
    },
    enentDropImg(e) {
      e.stopPropagation();
      e.preventDefault(); //必填字段
      let file = e.dataTransfer.files[0];
      this.imgChange(file);
    },
    dragleave(e) {
         this.isDragImg = true
      e.stopPropagation();
      e.preventDefault();
    },

    dragContain(e) {
      this.isDragImg = false;
    },
    leaveContain(e) {
      this.isDragImg = true;
      e.stopPropagation();
      e.preventDefault();
    },
    endContain(e) {
      this.isDragImg = false;
      e.stopPropagation();
      e.preventDefault();
    },
    onUploadProgress(e) {
      this.progressValue = (e.loaded / e.total) * 100;
    },
	
	
  },
};
</script>

<style lang="scss" scoped>
.faucet {
  font-family: Helvetica;
  font-size: 13px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #cc0000;
}
.uptick {
  cursor: pointer;
  font-family: Helvetica;
  text-decoration: underline;
  font-style: italic;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #7800f4;
}
::v-deep .v-messages__message {
  color: red;
}

::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot{
   min-height: 45px;
 }
.select {
  &::v-deep .v-list-item__content {
    color: #ffffff;
  }
}

.mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  .img {
    width: 60px;
    height: 60px;
  }
}
.card {
  width: 410px;
  min-height: 960px;

  .card_img {
    position: relative;
    margin-left: 50px !important;
    .title {
      font-family: Helvetica;
      font-weight: bold;
      font-size: 15px !important;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
      display: flex;
      align-items: center;
      .star {
        color: red;
        font-size: 18px;
        font-family: "宋体";
        margin-left: 10px;
      }
    }

    .des {
      height: 30px;
      font-family: Helvetica;
      font-size: 13px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #766983;
    }
    .url{
      font-family: Helvetica;
	font-size: 13px;
	font-weight: normal;
	letter-spacing: 0px;
	color: #270645;
    }
    .creatimg {
      width: 300px !important;
      height: 300px !important;
      border: dashed 2px #e3e3e3;
      position: relative;
      overflow: hidden;
      margin-top: 20px !important;
      &.imgDrap{
            border: dashed 2px #7800f4;
        }
      .uploadbox {
        text-align: center;
        // margin: 100px auto 0;
        width: 300px;
        height: 300px;
      }
    }

    .clo {
      visibility: hidden;
      position: absolute;
      right: 0px;
      top: 115px;
      border-radius: 50%;
      cursor: pointer;
      z-index: 9999;
    }

    &:hover .clo {
      visibility: visible;
    }

    .upload {
      text-align: center;
      vertical-align: middle;
      height: 450px;
      background-image: linear-gradient(#ffffff, #ffffff),
        linear-gradient(#f8f6fd, #f8f6fd);
      background-blend-mode: normal, normal;
      border-radius: 5px;
      border: solid 2px #e3e3e3;
    }

    .copyright {
      .content {
        p {
          margin-top: 20px;
          font-family: Helvetica;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;

          span {
            color: #6f58d9;
            text-decoration: none;
          }
        }
      }
    }
    .footer {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-bottom: 50px;

      .price {
        display: block;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        color: #766983;
      }

      .sub {
        margin: 14px auto;
        width: 300px;
        height: 50px;
        background-color: #7800f4;
        border-radius: 25px;
        opacity: 0.9;
        font-weight: bold;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
      }
      .nosub {
        margin: 14px auto;
        width: 300px;
        height: 50px;
        background-color: #766983 !important;
        border-radius: 25px;
        opacity: 0.9;
        font-weight: bold;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
      }
      .sub-dis {
        position: relative;
        pointer-events: none;
        background-image: linear-gradient(#766983, #766983),
          linear-gradient(#270645, #270645) !important;
        background-blend-mode: normal, normal;
        border-radius: 25px;
        opacity: 0.9;
      }

      .sub-dis::after {
        content: "";
        background-image: url(../../assets/loading.gif);
        background-size: 100%;
        display: inline-block;
        position: absolute;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-top: 5px;
      }
    }
  }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .progress_area {
    width: 270px;
    height: 10px;
    background-color: #bbb;
    border-radius: 30px;
    margin-top: 30px;
    margin-left: 10px;
    overflow: hidden;

    span {
      display: block;
      background-color: #766983;
      height: 100%;
      width: 0%;
    }
  }

  .top {
    margin: 0 52px;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 25px 0 15px;
      font-weight: bold !important;
      font-size: 25px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
    span {
      font-family: Helvetica;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }
  }
  .link {
    margin: 30px 48px 45px;
    ul {
      list-style: none;
      padding: 0;
      li {
        text-align: center;
        cursor: pointer;
        width: 290px;
        height: 220px;
        background-image: linear-gradient(#ffffff, #ffffff),
          linear-gradient(#f8f6fd, #f8f6fd);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
        border-radius: 5px;
        display: block;
        margin-bottom: 9px;
        img {
          display: block;
          margin: 20px auto 15px;
        }
        .infotit {
          font-family: Helvetica;
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
        .infotype {
          margin-top: 8px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #766983;
        }
        .infotitauc {
          height: 22px;
          font-weight: bold;
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          line-height: 22px;
          color: #270645;
          margin: 0 auto 7px;
        }
        .infos {
          margin-top: 8px;
          font-family: Helvetica;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .onlyread {
        .infotitauc,
        .infos {
          color: #ccc;
        }
      }
      li:hover {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.36);
      }
    }
  }
  &.mobile {
    .card_img {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    .clo {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .top {
      h3 {
        font-size: 20px;
      }
      p {
        height: auto;
      }
    }
    .link {
      ul {
        li {
          img {
            margin: 35px auto;
          }
        }
      }
    }
  }
}
</style>
