import Web3 from "web3";

export function getHash(data){
    let web3 = new Web3();
    let dataHash= web3.utils.sha3(data)
    return dataHash
}
export function stringToBytes32(str){
    let web3 = new Web3();
    const padded = web3.utils.padLeft(web3.utils.asciiToHex(str), 64); //
    return padded;

    // return web3.utils.soliditySha3({t: 'bytes32', v: str.toString()});
}

