<template>
  <v-text-field v-if="show" class="search-inpt"    :placeholder="placeholder" :class="{mobile: isMobile,darkBackground:this.$vuetify.theme.dark }"  :value="searchInput" @input="change" hide-details hide-spin-buttons clear-icon="mdi-close-circle"
            clearable >
    <template v-slot:prepend-inner><img class="prepend-inner" src="@/assets/icons/search.png" alt /></template>
  </v-text-field>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "searchInput",
  computed: {
    ...mapState({
      searchInput: (state) => state.searchInput,
    }),
    show: function () {
      return this.$route.name === 'LaunchPad' || this.$route.name === 'Rwa' || this.$route.name === 'Marketplace' || this.$route.name === 'Drops' || this.$route.name === 'MyOrders' ||  this.$route.name === 'ProductOrder'  
    },
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    }
  },
  watch: {
    show: {
      handler: function () {
        this.$store.dispatch("setSearchInput", undefined);
         
      },
      immediate: true
    }
  },
  props:{
    placeholder:{
      type:String
    }
  },
  mounted(){
  this.$store.dispatch("setSearchInput", '');
   window.eventBus.$on("clearSearch", this.clearSearch);

  },
 
  methods: {
    change: function (v) {
      this.$store.dispatch("setSearchInput", v);
      this.$emit("SearchChange", v);
    },
     clearSearch(){
    console.log("wxl 99999999999")
     this.$store.dispatch("setSearchInput", '');
  },
  },
};
</script>

<style lang="scss" >
.search-inpt {
  align-self: center;


  .prepend-inner {
    width: 17px;
    height: auto;
  }

  &.v-text-field.v-input--is-focused>.v-input__control>.v-input__slot:after {
    content: none !important;
  }

  .v-input__control {
    .v-input__slot::before {
      content: none;
    }
  }
 &.darkBackground{
     .v-input__slot {
   
    align-items: center;
    background-color: #272727 !important;
    border-radius: 20px;
    outline-color: #272727;
    border: 1px #272727 solid;
    .v-input__prepend-inner {
      align-self: center;
      margin-top: 0;
      margin-left: 14px;
      margin-right: 13px;
      padding-right: 0;
    }
    .v-text-field__slot {
      flex: 1;
      padding-right: 35px;
      width: unset;
      input {
        width: auto;
      }
    }
  }
  }
  .v-input__slot {
    height: 40px;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 20px;
    outline-color: #f0f0f0;

    .v-input__prepend-inner {
      align-self: center;
      margin-top: 0;
      margin-left: 14px;
      margin-right: 13px;
      padding-right: 0;
    }
    .v-input__icon{
      margin-top:4px !important;
      margin-right: 10px !important;
      margin-left: -40px !important;
    }

    .v-text-field__slot {
      flex: 1;
      padding-right: 35px;
      width: unset;

      input {
        width: auto;
      }
    }
  }
}

.search-inpt.mobile {
  .v-input__slot {
    .v-text-field__slot {
      padding-right: unset;
    }
  }
}
</style>
