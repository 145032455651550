<template>
  <div id="container">
    <v-card :class="{ card: true, mobile: isMobile }">
      <img
        class="clo"
        @click="closeDialog"
        :src="$vuetify.theme.dark?darkcloseIcon:closeIcon"
        alt=""
      />
      <div class="top">
        <h3 class="end" :class="{darkFont:$vuetify.theme.dark }" v-if="!pageValue.nftId">{{ $t("rwa_delete_title") }}</h3>
        <h3 class="end" :class="{darkFont:$vuetify.theme.dark }" v-else>{{ $t("popupAuctionDefectPriceBurnNFT") }}</h3>
        <h3 class="content" :class="{darkFont:$vuetify.theme.dark }"  v-if="!pageValue.nftId">
          {{ $t("rwa_delete_description") }}
        </h3>
        <h3 class="content" :class="{darkFont:$vuetify.theme.dark }" v-else>
          {{ $t("popupBurnYourMinted") }}
        </h3>
      </div>
      <div class="footer">
        <button class="sub v-btn uc" :class="{ 'sub-dis': isPay }" submit @click="subCommit">
          {{ $t("popupTransferCommit") }}
        </button>
      </div>
    </v-card>
    <uComponents  ref="ucom"></uComponents>
  </div>
</template>
<script>
import api from '@/api'
export default {
  data: () => ({
    open: true,
    isPay: false,
    multiNFTids:[],
    closeIcon:require('@/assets/btn_close.png'),
         darkcloseIcon:require('@/assets/darkimage/btn_close1.png'),
  }),
  props: {
    pageValue: {
      type: Object,
    },
    offSaleTokenIds: {
      type: Array,
    },
    offsaleType:{
      type:String
    },
    burntype:{
      type:String
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
     if(this.offSaleTokenIds &&this.offSaleTokenIds.length >0){
       for (let index = 0; index < this.offSaleTokenIds.length; index++) {
     this.multiNFTids+=this.offSaleTokenIds[index]+ ",";
     
   }
    this.multiNFTids = this.multiNFTids.substring(0, this.multiNFTids.lastIndexOf(','));
     }
      
  },
  methods: {
    async subCommit() {
      this.isPay = true
      let params = {
        nftAddress: this.pageValue.nftAddress,
        owner:this.$store.state.did,
        nftId:this.offsaleType == 'multi'? this.multiNFTids :this.pageValue.nftId,
		nftType:this.pageValue.nftType,
		serialNumber:this.pageValue.serialNumber?this.pageValue.serialNumber:null
      }
        let res = await api.home.nftBurn(params);
        if(res.success == true)
        {
          debugger
           this.$toast("success", this.$t("success")).then(() => {
            window.eventBus.$emit("backgroundUpdate");
              this.$emit("getOpen", false)
              this.isPay = false
              window.eventBus.$emit("collectionPay");
          })
        }else{
           this.$toast("error", this.$t(res.msg));

        }
        // this.$refs.promptBox.show(res.msg);
        
    },
    closeDialog() {
      this.open = false;
      this.$emit("getOpen", this.open);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;
  min-height: 274px;
  width: 600px;
  .top {
    display: flex;
    flex-direction: column;
    margin: 42px 70px 30px;
    .end {
      margin: 0 auto 23px;
      // width: 117px;
      // height: 20px;
      font-weight: bold!important;
      font-size: 25px!important;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #270645;
    }

    .content {
      height: 72px;
      font-weight: bold;;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      text-align: center;
      color: #270645;
    }
  }
  .clo {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 31px;
    border-radius: 50%;
    margin-right: 0;
    cursor: pointer;
  }
  .footer {
    margin: 0 auto;
    .sub {
      margin: 30px auto;
      width: 450px;
      height: 51px;
      background-blend-mode: normal, normal;
      background-color: #7800f4;
      border-radius: 25px;
      opacity: 0.9;
      font-weight: bold;;
      font-size: 20px;
      color: #ffffff;
    }
    .sub-dis {
  position: relative;
  pointer-events: none;
  background-image: linear-gradient(#766983,
      #766983),
    linear-gradient(#270645,
      #270645) !important;
  background-blend-mode: normal,
    normal;
  border-radius: 25px;
  opacity: 0.9;
}

.sub-dis::after {
  content: "";
  background-image: url(../../assets/loading.gif);
  background-size: 100%;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 130px;
}

  }
  &.mobile {
    .top {
      width: 100%;
      margin: 30px 10% 0;

      .content {
        width: 80%;
        height: auto;
        word-wrap: break-word;
      }
    }
    .footer {
      width: 100%;
      margin-left: 10%;
      .sub {
        width: 80%;
      }
    }
  }
}

img {
  display: inline-block;
  width: 126px;
  height: 126px;
  border-radius: 5px;
  margin: 1px 22px 10px 52px;
}
</style>




